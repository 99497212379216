import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AccommodationInfoCard } from './AccommodationInfoCard';
import { Accommodation, useAppContext } from '../../contexts/GlobalStateContext';
import { getReservas, Reserva } from '../../apis/accommodations/getReservas';
import { accommodationValidationService } from '../../services/validations/AccommodationValidation';
import AssignedAccommodation from './AssignedAccommodation';
import { getAccommodations } from '../../apis/accommodations/getAccommodations';

interface User {
  id: string;
  nombre: string;
  apellidop: string;
  apellidom: string;
  iglesia: string;
  asiste_esposa: boolean;
  pais: string;
  acreditado: boolean;
  rut: string;
  vehiculoPropio: boolean;
  email?: string;
  telefono?: string;
  user_id?: number;
  correo?: string;
  esposa?: {
    nombre: string;
    apellidop: string;
    apellidom: string;
    rut: string;
    pais?: string;
  };
}

const findBestAccommodation = (
  accommodations: Accommodation[],
  user: User
): Accommodation | null => {
  const needsParking = user.vehiculoPropio;
  const needsDoubleRoom = user.asiste_esposa;
  
  const suitableAccommodations = accommodations.filter(acc => {
    if (needsParking && (!acc.estacionamiento || acc.capacidadEstacionamiento < 1)) return false;

    const hasAvailableRoom = acc.habitaciones.some(hab => {
      const isAvailable = !hab.asignado_a && !hab.reserva;
      
      if (!isAvailable) return false;

      if (needsDoubleRoom) {
        return hab.tipoCamas.some(tc => tc.tipoDeCama.toLowerCase() === 'matrimonial');
      }
      return hab.tipoCamas.some(tc => 
        ['una plaza', 'plaza y media'].includes(tc.tipoDeCama.toLowerCase())
      );
    });

    return hasAvailableRoom;
  });

  return suitableAccommodations[0] || null;
};

const AccommodationDetail: React.FC = () => {
  const location = useLocation();
  const user = location.state?.visitant as User;
  const { accommodations, assignAccommodation, unassignAccommodation, setAccommodations } = useAppContext();
  
  const [reservas, setReservas] = useState<Reserva[]>([]);
  const [selectedAccommodation, setSelectedAccommodation] = useState<number | null>(null);
  const [filter, setFilter] = useState<'all' | 'parking'>('all');
  const [tipoCama, setTipoCama] = useState<number | null>(null);
  const [minEstacionamiento, setMinEstacionamiento] = useState<number>(0);
  const [fechaInicio, setFechaInicio] = useState<string | null>(null);
  const [fechaFin, setFechaFin] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchReservas = async () => {
      try {
        const data = await getReservas();
        setReservas(data);
      } catch (error) {
        console.error('Error al cargar reservas:', error);
      }
    };
    fetchReservas();
  }, []);

  useEffect(() => {
    console.log('Estado de alojamientos actualizado:', accommodations);
  }, [accommodations]);

  const handleViewAccommodation = () => {
    const assignedAccommodation = accommodations.find(
      acc => acc.asignado_a === parseInt(user.id)
    );
    if (assignedAccommodation) {
      setSelectedAccommodation(assignedAccommodation.id);
    }
  };

  const handleEditAccommodation = () => {
    setSelectedAccommodation(null);
  };

  const handleDeleteAccommodation = async () => {
    try {
      const assignedAccommodation = accommodations.find(
        acc => acc.asignado_a === parseInt(user.id)
      );
      if (assignedAccommodation) {
        await unassignAccommodation(assignedAccommodation.id);
        setSuccessMessage('Alojamiento liberado exitosamente');
        setTimeout(() => setSuccessMessage(null), 3000);
      }
    } catch (error) {
      console.error('Error al liberar alojamiento:', error);
      setValidationErrors(['Error al liberar el alojamiento']);
    }
  };

  const handleAssignDirect = async () => {
    try {
      const bestAccommodation = findBestAccommodation(accommodations, user);
      if (!bestAccommodation) {
        alert('No se encontraron alojamientos disponibles.');
        return;
      }

      const bestRoom = bestAccommodation.habitaciones.find(hab => {
        if (hab.asignado_a || hab.reserva) return false;

        return user.asiste_esposa 
          ? hab.tipoCamas.some(tc => tc.tipoDeCama === 'matrimonial')
          : hab.tipoCamas.some(tc => ['una plaza', 'plaza y media'].includes(tc.tipoDeCama));
      });

      if (!bestRoom) {
        alert('No se encontró habitación disponible.');
        return;
      }

      await assignAccommodation(bestRoom.id, parseInt(user.id));
      const updatedAccommodations = await getAccommodations();
      setAccommodations(updatedAccommodations);

      const tipoCama = bestRoom.tipoCamas.map(tc => tc.tipoDeCama).join(', ');
      setSuccessMessage(
        `Alojamiento asignado exitosamente:\n` +
        `${bestAccommodation.nombre}\n` +
        `Habitación ${bestRoom.numero}\n` +
        `Tipo de cama: ${tipoCama}\n` +
        `Baño: ${bestRoom.tipoBano.tipoDeBano}`
      );
    } catch (err) {
      console.error('Error:', err);
      setValidationErrors(['Error al asignar alojamiento']);
    }
  };
  
  const handleAssign = async (accommodationId: number) => {
    if (!user?.id) {
      console.error('ID de usuario no válido');
      return;
    }
  
    try {
      const accommodation = accommodations.find((acc) =>
        acc.habitaciones.some((hab) => hab.id === accommodationId)
      );
      if (!accommodation) return;
  
      const habitacion = accommodation.habitaciones.find((hab) => hab.id === accommodationId);
      if (!habitacion) {
        throw new Error('No se pudo encontrar la habitación seleccionada.');
      }
  
      const { isValid, errors } = accommodationValidationService.validateAccommodation(accommodation, user);
  
      if (!isValid) {
        setValidationErrors(errors);
        return;
      }
  
      await assignAccommodation(accommodationId, parseInt(user.id));
  
      setAccommodations((prevAccommodations: Accommodation[]) => 
        prevAccommodations.map((acc: Accommodation) => 
          acc.id === accommodation.id 
            ? {
                ...acc,
                asignado_a: parseInt(user.id),
                disponible: false,
                habitaciones: acc.habitaciones.map((hab) =>
                  hab.id === accommodationId ? { ...hab, asignado_a: parseInt(user.id) } : hab
                )
              }
            : acc
        )
      );
  
      setValidationErrors([]);
      setSelectedAccommodation(null);
      setSuccessMessage('Alojamiento asignado exitosamente');
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Error al asignar alojamiento:', err);
      setValidationErrors(['Error al asignar el alojamiento']);
    }
  };

  const filteredAccommodations = useCallback(() => {
    if (!user) return [];
  
    return accommodations.filter((acc: Accommodation) => {
      const hasAvailableRooms = acc.habitaciones.some(hab => {
        const isAvailable = !hab.asignado_a;
        const matchesTipoCama = !tipoCama || hab.tipoCamas.some(tc => tc.id === tipoCama);
        return isAvailable && matchesTipoCama;
      });
  
      const { isValid } = accommodationValidationService.validateAccommodation(acc, user);
      
      if (acc.capacidadEstacionamiento < minEstacionamiento) return false;
  
      if (fechaInicio && fechaFin) {
        const filtroInicio = new Date(fechaInicio);
        const filtroFin = new Date(fechaFin);
  
        const solapada = reservas.some((reserva) => {
          const reservaInicio = new Date(reserva.fechaInicio);
          const reservaFin = new Date(reserva.fechaFin);
          return (
            reserva.habitacionId &&
            acc.habitaciones.some((hab) => hab.id === reserva.habitacionId) &&
            ((filtroInicio >= reservaInicio && filtroInicio <= reservaFin) ||
              (filtroFin >= reservaInicio && filtroFin <= reservaFin) ||
              (reservaInicio >= filtroInicio && reservaInicio <= filtroFin))
          );
        });
  
        if (solapada) return false;
      }
  
      if (filter === 'parking') {
        return hasAvailableRooms && acc.estacionamiento && isValid;
      }
  
      return hasAvailableRooms && isValid;
    });
  }, [accommodations, filter, tipoCama, minEstacionamiento, fechaInicio, fechaFin, reservas, user]);

  if (!user) {
    return <div className="p-6 text-red-600">Error: No se ha seleccionado ningún usuario</div>;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {successMessage && (
        <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-md">
          <p className="text-green-700">{successMessage}</p>
        </div>
      )}

      <div className="bg-white rounded-lg shadow mb-6">
        <div className="p-6">
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-4">INFORMACIÓN DEL ASISTENTE</h2>
              <div className="grid grid-cols-2 gap-y-4">
                <div>
                  <p className="text-sm text-gray-500">NOMBRE</p>
                  <p className="text-sm text-gray-900">{`${user.nombre} ${user.apellidop} ${user.apellidom}`}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">CORREO ELECTRÓNICO</p>
                  <p className="text-sm text-gray-900">{user.email}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">RUT/PASAPORTE</p>
                  <p className="text-sm text-gray-900">{user.rut}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">VEHÍCULO PROPIO</p>
                  <p className="text-sm text-gray-900">{user.vehiculoPropio ? 'Sí' : 'No'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">PAÍS</p>
                  <p className="text-sm text-gray-900">{user.pais}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">MESA ASIGNADA</p>
                  <p className="text-sm text-gray-900">Mesa 14</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">TELÉFONO</p>
                  <p className="text-sm text-gray-900">{user.telefono}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">CUSTODIA</p>
                  <p className="text-sm text-gray-900">N/A</p>
                </div>
              </div>
            </div>

            {user.asiste_esposa && user.esposa && (
              <div>
                <h2 className="text-lg font-medium text-gray-900 mb-4">INFORMACIÓN DE PAREJA</h2>
                <div className="grid grid-cols-1 gap-y-4">
                  <div>
                    <p className="text-sm text-gray-500">NOMBRE</p>
                    <p className="text-sm text-gray-900">
                      {`${user.esposa.nombre} ${user.esposa.apellidop} ${user.esposa.apellidom}`}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">RUT/PASAPORTE</p>
                    <p className="text-sm text-gray-900">{user.esposa.rut}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">PAÍS</p>
                    <p className="text-sm text-gray-900">{user.esposa.pais || 'Chipre'}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-b-lg border-t border-gray-200">
          {accommodations.some((acc) => {
            return acc.habitaciones.some((hab) => {
              console.log('Verificando habitación:', {
                habId: hab.id,
                asignadoA: hab.asignado_a,
                userId: parseInt(user.id),
                reserva: hab.reserva
              });
              return hab.asignado_a === parseInt(user.id) || 
                     (hab.reserva?.usuario && hab.reserva.usuario.user_id === parseInt(user.id));
            });
          }) ? (
            <AssignedAccommodation
              accommodation={accommodations.find((acc) =>
                acc.habitaciones.some((hab) => 
                  hab.asignado_a === parseInt(user.id) || 
                  (hab.reserva?.usuario && hab.reserva.usuario.user_id === parseInt(user.id))
                )
              )!}
              onView={handleViewAccommodation}
              onEdit={handleEditAccommodation}
              onDelete={handleDeleteAccommodation}
              visitantId={parseInt(user.id)}
              user={user}
            />
          ) : (
            <div className="flex justify-between items-center">
              <div>
                <span className="text-sm font-medium text-gray-900">ALOJAMIENTO ASIGNADO: </span>
                <span className="text-sm text-gray-700">NO HAY ALOJAMIENTO ASIGNADO</span>
              </div>
              <button 
                onClick={handleAssignDirect}
                className="ml-4 bg-indigo-600 text-white px-4 py-2 rounded text-sm font-medium hover:bg-indigo-700"
              >
                ASIGNACIÓN DIRECTA
              </button>
            </div>
          )}
        </div>
      </div>

      {validationErrors.length > 0 && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded mb-4">
          <ul className="list-disc pl-5">
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Available Accommodations Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Alojamientos disponibles</h2>
          <div className="flex space-x-2">
            <button
              onClick={() => setFilter('all')}
              className={`px-4 py-2 rounded ${filter === 'all' ? 'bg-indigo-600 text-white' : 'bg-gray-200'}`}
            >
              Todos
            </button>
            <button
              onClick={() => setFilter('parking')}
              className={`px-4 py-2 rounded ${filter === 'parking' ? 'bg-indigo-600 text-white' : 'bg-gray-200'}`}
            >
              Con estacionamiento
            </button>
          </div>
        </div>

        {selectedAccommodation && (
          <AccommodationInfoCard
            accommodation={accommodations.find((acc) => acc.id === selectedAccommodation) || null}
            onClose={() => setSelectedAccommodation(null)}
            onAssign={() => handleAssign(selectedAccommodation)}
            visitantId={parseInt(user.id)}
            reservas={reservas}
            habitaciones={accommodations.flatMap((acc) => acc.habitaciones)}
          />
        )}

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nombre</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Dirección</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tipo</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Habitaciones</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Estacionamiento</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Acciones</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAccommodations().map((acc: Accommodation) => (
                <tr key={acc.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{acc.nombre}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{acc.direccion}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{acc.casa_o_dpto}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {`${acc.habitacionesCount || 0} hab / ${acc.camasCount || 0} camas`}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {acc.estacionamientoTexto}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => setSelectedAccommodation(acc.id)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Ver detalles
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {filteredAccommodations().length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No se encontraron alojamientos disponibles que cumplan con los criterios requeridos.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccommodationDetail;