import React from 'react';
import { X } from 'lucide-react';
import { Accommodation, Habitacion } from '../../contexts/GlobalStateContext';

interface RoomManagementProps {
  accommodation: Accommodation;
  onClose: () => void;
}

export const RoomManagement: React.FC<RoomManagementProps> = ({
  accommodation,
  onClose,
}) => {
  const renderRoomStatus = (habitacion: Habitacion) => {
    if (habitacion.asignado_a || habitacion.reserva) {
      const userInfo = {
        nombre: habitacion.reserva?.usuario?.nombre || '',
        apellido: habitacion.reserva?.usuario?.apellidop || ''
      };
      
      const displayName = userInfo.nombre && userInfo.apellido 
        ? `${userInfo.nombre} ${userInfo.apellido}` 
        : habitacion.asignado_a 
          ? `Usuario ID: ${habitacion.asignado_a}` 
          : 'Usuario Asignado';
  
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          Ocupado - {displayName}
        </span>
      );
    }
    return (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        Disponible
      </span>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold">
            Habitaciones - {accommodation.nombre}
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-6">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Número/Piso
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Tipo de Baño
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Camas
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Estado
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {accommodation.habitaciones.map((habitacion) => (
                  <tr key={habitacion.numero}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {habitacion.numero}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {habitacion.tipo_bano}
                    </td>
                    <td className="px-6 py-4">
                      {habitacion.camas?.map((cama, index) => (
                        <div key={`${habitacion.id}-${index}`} className="text-sm">
                          {cama.cantidad} {cama.tipo}
                        </div>
                      ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {renderRoomStatus(habitacion)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {accommodation.habitaciones.length === 0 && (
              <div className="text-center py-4 text-gray-500">
                No hay habitaciones registradas
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomManagement;