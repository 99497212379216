//useCustody.tsx
import { CustodySpace } from '../../contexts/GlobalStateContext';

interface UpdateSpace {
    spaceId: number;
    ocupado?: boolean; // Marked as optional
}

interface AssignedSpace {
    idUsuario: number | null;
}

    export const updateCustody = async (data: UpdateSpace & AssignedSpace) => {
        try {
            const body: any = {
                idUsuario: data.idUsuario === null ? 0 : data.idUsuario,
            };
        
            const response = await fetch(
                `https://agy-estudio-biblico-production.up.railway.app/custodia/${data.spaceId}/asignar`,
                {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body),
                }
            );
        
            if (!response.ok) {
                const errorDetails = await response.text();
                console.error('Server response:', errorDetails);
                throw new Error(`Error updating custody space: ${errorDetails}`);
            }

            const updatedSpace = await response.json();
            
            // Si hay un usuario asignado, obtener sus datos inmediatamente
            if (data.idUsuario && data.idUsuario !== 0) {
                try {
                    const userResponse = await fetch(
                        `https://agy-estudio-biblico-production.up.railway.app/users/${data.idUsuario}`
                    );
                    
                    if (userResponse.ok) {
                        const userData = await userResponse.json();
                        
                        // Crear el objeto completo con toda la información necesaria
                        const completeSpace: CustodySpace = {
                            ...updatedSpace,
                            ocupado: true,
                            user: {
                                user_id: userData.user_id,
                                correo: userData.correo,
                                nombre: userData.nombre,
                                apellidop: userData.apellidop,
                                apellidom: userData.apellidom,
                                rut: userData.rut
                            },
                            usuarioId: userData.user_id
                        };
                        
                        return completeSpace;
                    }
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            }

            return updatedSpace;
        } catch (error) {
            console.error('Error in updateCustody:', error);
            throw error;
        }
    };

    export const releaseCustody = async (spaceId: number) => {
        try {
            const response = await fetch(
                `https://agy-estudio-biblico-production.up.railway.app/custodia/${spaceId}/liberar`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                const errorDetails = await response.text();
                throw new Error(`Error releasing custody space: ${errorDetails}`);
            }

            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error releasing space:', error);
            throw error;
        }
    };

    export const deleteCustody = async (id: number) => {
    try {
        const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/custodia/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        });

        if (!response.ok) {
        throw new Error(`Error deleting custody space: ${response.statusText}`);
        }

        return id;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
    };

export default { updateCustody, deleteCustody, releaseCustody };
