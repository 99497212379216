import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserPlusIcon, EyeIcon } from '@heroicons/react/24/outline';

const usersOptions = [
  { name: 'Ver Visitas', href: 'list', icon: EyeIcon }, 
  { name: 'Agregar Visita', href: 'add', icon: UserPlusIcon },
];

const visibleOptions = usersOptions.filter(() => false);

const UsersPage: React.FC = () => {
  return (
    <div className="p-11 bg-white-50 ml-0">
      <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Visitas y Roles</h1>

      {/* Menú de navegación de opciones en fila centrada */}
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-center items-center gap-2 border-x border-gray-900/5 py-4">
          {visibleOptions.map((option) => (
            <Link
              key={option.name}
              to={option.href}
              className="flex items-center justify-center gap-x-2.5 p-2 px-4 text-sm font-semibold text-gray-900 hover:bg-gray-100"
            >
              <option.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
              {option.name}
            </Link>
          ))}
        </div>
      </div>

      {/* Renderizado del subcomponente según la ruta */}
      <div className="mt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default UsersPage;
