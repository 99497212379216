import React from 'react';
import { Products } from '../../../src/contexts/GlobalStateContext'; // Adjust the import path based on your structure

// Function to get products with low stock
export const getLowStockProducts = (products: Products[]): Products[] => {
    return products.filter(product => 
        Number(product.cantidad) < Number(product.stock_minimo)
    );
};


    // Low stock alert component
    export const LowStockAlert: React.FC<{ lowStockProducts: Products[] }> = ({ lowStockProducts }) => {
    if (lowStockProducts.length === 0) return null;

    return (
        <div className="mb-6 p-4 rounded-lg border-l-4 border-red-500 bg-red-50">
        <div className="flex items-center mb-2">
            <div className="flex-shrink-0">
            <svg 
                className="h-5 w-5 text-red-500" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor"
            >
                <path 
                fillRule="evenodd" 
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" 
                clipRule="evenodd" 
                />
            </svg>
            </div>
            <div className="ml-3">
            <h3 className="text-red-800 font-medium">
                ¡Alerta de Bajo Stock!
            </h3>
            </div>
        </div>
        <div className="ml-8">
            <p className="text-red-700 mb-2">
            Los siguientes productos necesitan reabastecimiento:
            </p>
            <ul className="list-disc pl-5 space-y-1">
            {lowStockProducts.map(product => (
                <li key={product.id} className="text-red-600">
                <span className="font-medium">{product.nombre_producto}</span>
                <span className="text-red-500">
                    {' '}- Cantidad actual: {product.cantidad}
                    {' '}(Mínimo requerido: {product.stock_minimo})
                </span>
                </li>
            ))}
            </ul>
        </div>
        </div>
    );
};
