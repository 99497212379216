import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustodySpaces } from '../apis/custody/getCustodySpaces';
import { getVehicles } from '../apis/vehicles/getVehicles';
import { getProducts } from '../apis/inventory/getProducts';
import { getAccommodations } from '../apis/accommodations/getAccommodations';
import { assignAccommodation as assignAccommodationApi, unassignAccommodation as unassignAccommodationApi } from '../apis/accommodations/useAccommodations';

export interface LoginResponse {
  user: {
    user_id: number;
    correo: string;
    rut: string;
    nombre: string;
    apellidop: string;
    apellidom: string;
    edad: number;
    telefono: string;
    iglesia: string;
    acreditado: boolean;
    grado: string;
    zona: string;
    pais: string;
    perfil: string;
    hipertension: boolean;
    diabetes: boolean;
    celiaco: boolean;
    vehiculoPropio: boolean;
    esposa: boolean;
    rut_esposa: string;
    nombre_esposa: string;
    apellidop_esposa: string;
    apellidom_esposa: string;
    edad_esposa: number;
    hipertension_esposa: boolean;
    diabetes_esposa: boolean;
    celiaco_esposa: boolean;
  };
}

export interface User {
  user_id: number;
  correo: string;
  rut: string;
  nombre: string;
  apellidop: string;
  apellidom: string;
}

export interface CustodySpace {
  id: number;
  ubicacion: string;
  capacidad: 'Maleta' | 'Bolso de mano';
  ocupado: boolean;
  user?: User | null;
  usuarioId?: number | null;
}

export interface Vehicle {
  id: number;
  marca: string;
  modelo: string;
  patente: string;
  userId: number | null;
  ocupado: boolean;
  user?: User | null;
}

export interface Products {
  id?: number;
  nombre_producto: string;
  tipo: string;
  stock_minimo: number;
  cantidad: number;
  ubicacion: string;
  apto_celiaco: boolean;
  apto_hipertenso: boolean;
  apto_diabetico: boolean;
}

export interface TipoCama {
  id: number;
  tipoDeCama: string;
}

export interface TipoBano {
  id: number;
  tipoDeBano: string;
}

export interface Cama {
  tipo: string;
  cantidad: number;
}

export interface Reserva {
  id: number;
  fechaIngreso: string;
  fechaSalida: string;
  habitacion: {
    id: number;
    habitacionPiso: number;
    camaPorHab: number;
  };
  usuario: {
    user_id: number;
    correo: string;
    nombre: string;
    apellidop: string;
    apellidom: string;
    rut: string;
  };
}

export interface Habitacion {
  id: number;
  habitacionPiso: number;
  camaPorHab: number;
  hospedajeId?: number;
  tipoBanoId?: number;
  numero: number;
  descripcion: string;
  tipo_bano: string;
  camas: Cama[];
  tipoBano: TipoBano;
  tipoCamas: TipoCama[];
  asignado_a?: number;
  reserva?: Reserva;
  hospedaje: {
    id: number;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    direccion: string;
    rut: string;
    iglesia: string;
    telefono: string;
    estacionamiento: boolean;
    capacidadEstacionamiento: number;
    ascensor: boolean;
    esCasa: boolean;
    esDpto: boolean;
    pisoDepartamento: number;
    comuna: string;
    sector: string;
    local: string;
  };
}

export interface Accommodation {
  id: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  rut: string;
  telefono: string;
  direccion: string;
  comuna: string;
  sector: string;
  iglesia: string;
  local: string;
  estacionamiento: boolean;
  capacidadEstacionamiento: number;
  esCasa: boolean;
  esDpto: boolean;
  pisoDepartamento: number;
  ascensor: string;
  habitaciones: Habitacion[];
  casa_o_dpto: 'CASA' | 'DPTO';
  estacionamientoTexto: string;
  habitacionesCount: number;
  camasCount: number;
  disponible: boolean;
  asignado_a?: number;
}

interface AppContextInterface {
  isAuthenticated: boolean;
  user: LoginResponse | null;
  login: (email: string, password: string) => Promise<LoginResponse | null>;
  logout: () => void;
  custodySpaces: CustodySpace[];
  addCustodySpace: (newSpace: CustodySpace) => void;
  assignSpace: (id: number) => void;
  freeSpace: (id: number) => void;
  removeCustodySpace: (id: number) => void;
  getCustodySpaces: () => Promise<CustodySpace[]>;
  vehicles: Vehicle[];
  addVehicle: (vehicleData: Omit<Vehicle, 'id'>) => void;
  assignVehicle: (id: number, userData?: User) => void;
  freeVehicle: (id: number) => void;
  removeVehicle: (id: number) => void;
  setVehicles: (vehicles: Vehicle[]) => void;
  products: Products[];
  setProducts: React.Dispatch<React.SetStateAction<Products[]>>;
  addProduct: (product: Products) => void;
  updateProduct: (id: number, updatedProduct: Partial<Products>) => void;
  deleteProduct: (id: number) => void;
  refreshProducts: () => Promise<void>;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  accommodations: Accommodation[];
  setAccommodations: React.Dispatch<React.SetStateAction<Accommodation[]>>;
  assignAccommodation: (accommodationId: number, visitantId: number) => void;
  unassignAccommodation: (accommodationId: number) => void;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  fetchUserById: (id: number) => Promise<User | null>;
}

const AppContext = createContext<AppContextInterface | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [user, setUser] = useState<LoginResponse | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const fetchUserById = async (id: number): Promise<User | null> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_RAILWAY_API_URL}/users/${id}`);
      if (!response.ok) throw new Error('Error al obtener el usuario');
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [custodySpaces, setCustodySpaces] = useState<CustodySpace[]>([]);
  const [accommodations, setAccommodations] = useState<Accommodation[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [products, setProducts] = useState<Products[]>([]);
  const navigate = useNavigate();
  

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para la Custodia de equipaje
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const loadCustodySpaces = async () => {
      try {
        const spaces = await getCustodySpaces();
        if (Array.isArray(spaces)) {
          setCustodySpaces(spaces);
        } else {
          console.error("Fetched data is not an array:", spaces);
        }
      } catch (error) {
        console.error('Failed to fetch custody spaces:', error);
      }
    };
  
    loadCustodySpaces();
  }, []);

  const addCustodySpace = (newSpace: CustodySpace) => {
    setCustodySpaces((prevSpaces) => [...prevSpaces, newSpace]);
  };

  const assignSpace = async (id: number, userData?: User) => {
    try {
      const updatedSpaces = custodySpaces.map((space) =>
        space.id === id 
          ? { 
              ...space, 
              ocupado: true,
              user: userData ? {
                user_id: userData.user_id,
                correo: userData.correo,
                nombre: userData.nombre,
                apellidop: userData.apellidop,
                apellidom: userData.apellidom,
                rut: userData.rut
              } : null,
              usuarioId: userData?.user_id || null
          } 
          : space
      );
      setCustodySpaces(updatedSpaces);
      
      const spaces = await getCustodySpaces();
      if (Array.isArray(spaces)) {
        setCustodySpaces(spaces);
      }
    } catch (error) {
      console.error('Error assigning space:', error);
    }
  };

  const freeSpace = (id: number) => {
    const updatedSpaces = custodySpaces.map((space) =>
      space.id === id ? { ...space, ocupado: false, usuarioId: null } : space
    );
    setCustodySpaces(updatedSpaces);
  };

  const removeCustodySpace = (id: number) => {
    const updatedSpaces = custodySpaces.filter((space) => space.id !== id);
    setCustodySpaces(updatedSpaces);
  };

    /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Alojamientos
  
  ++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    const loadAccommodations = async () => {
      try {
        const fetchedAccommodations = await getAccommodations();
        setAccommodations(fetchedAccommodations);
      } catch (error) {
        console.error('Failed to fetch accommodations:', error);
      }
    };

    loadAccommodations();
  }, []);

  const assignAccommodation = async (accommodationId: number, visitantId: number) => {
    try {
      await assignAccommodationApi({ accommodationId, visitantId }, accommodations.flatMap(acc => acc.habitaciones));
      
      const [updatedAccommodations, updatedUser] = await Promise.all([
        getAccommodations(),
        fetchUserById(visitantId)
      ]);

      setAccommodations(updatedAccommodations);
      if (updatedUser) {
        setUsers(prevUsers => prevUsers.map(user => 
          user.user_id === visitantId ? updatedUser : user
        ));
      }
    } catch (error) {
      console.error('Error asignando alojamiento:', error);
      throw error;
    }
  };

  const unassignAccommodation = async (accommodationId: number) => {
    try {
      await unassignAccommodationApi(accommodationId);
      setAccommodations(prev => prev.map(acc => 
        acc.id === accommodationId 
          ? { 
              ...acc, 
              disponible: true, 
              asignado_a: undefined,
            }
          : acc
      ));
    } catch (error) {
      console.error('Error unassigning accommodation:', error);
      throw error;
    }
  };


  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Vehículos
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const loadVehicles = async () => {
      try {
        const fetchedVehicles = await getVehicles();
        if (Array.isArray(fetchedVehicles)) {
          setVehicles(fetchedVehicles);
        } else {
          console.error("Fetched vehicles data is not an array:", fetchedVehicles);
        }
      } catch (error) {
        console.error('Failed to fetch vehicles:', error);
      }
    };
  
    loadVehicles();
  }, []);

  const addVehicle = (vehicleData: Omit<Vehicle, 'id'>) => {
    const newVehicle = { ...vehicleData, id: Date.now() };
    setVehicles((prevVehicles) => [...prevVehicles, newVehicle]);
  };

  const assignVehicle = async (id: number, userData?: User) => {
    try {
      const updatedVehicles = vehicles.map((vehicle) =>
        vehicle.id === id 
          ? { 
              ...vehicle, 
              ocupado: true,
              user: userData ? {
                user_id: userData.user_id,
                correo: userData.correo,
                nombre: userData.nombre,
                apellidop: userData.apellidop,
                apellidom: userData.apellidom,
                rut: userData.rut
              } : null,
              userId: userData?.user_id || null
          } 
          : vehicle
      );
      setVehicles(updatedVehicles);

      const fetchedVehicles = await getVehicles();
      if (Array.isArray(fetchedVehicles)) {
        setVehicles(fetchedVehicles);
      }
    } catch (error) {
      console.error('Error assigning vehicle:', error);
    }
  };

  const freeVehicle = async (id: number) => {
    try {
      const updatedVehicles = vehicles.map((vehicle) =>
        vehicle.id === id 
          ? { 
              ...vehicle, 
              ocupado: false, 
              userId: null, 
              user: null 
          } 
          : vehicle
      );
      setVehicles(updatedVehicles);

      const fetchedVehicles = await getVehicles();
      if (Array.isArray(fetchedVehicles)) {
        setVehicles(fetchedVehicles);
      }
    } catch (error) {
      console.error('Error releasing vehicle:', error);
    }
  };

  const removeVehicle = (id: number) => {
    const updatedVehicles = vehicles.filter((vehicle) => vehicle.id !== id);
    setVehicles(updatedVehicles);
  };

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Productos
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const fetchedProducts = await getProducts();
        if (Array.isArray(fetchedProducts)) {
          console.log('Products loaded:', fetchedProducts);
          setProducts(fetchedProducts);
        } else {
          console.error("Fetched products data is not an array:", fetchedProducts);
        }
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };
  
    loadProducts();
  }, []);

  const refreshProducts = async () => {
    try {
      const fetchedProducts = await getProducts();
      if (Array.isArray(fetchedProducts)) {
        setProducts(fetchedProducts);
      }
    } catch (error) {
      console.error('Error refreshing products:', error);
      throw error;
    }
  };

  const addProduct = (product: Products) => {
    if (product.cantidad < product.stock_minimo) {
      setError('La cantidad inicial no puede ser menor al stock mínimo.');
      return;
    }
  
    setProducts(prevProducts => [...prevProducts, product]);
    setError(null);
  };

  const updateProduct = (id: number, updatedProduct: Partial<Products>) => {
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product.id === id
          ? { ...product, ...updatedProduct }
          : product
      )
    );
  };

  const deleteProduct = (id: number) => {
    setProducts(prevProducts => prevProducts.filter(product => product.id !== id));
  };

 /*+++++++++++++++++++++++++++++++++++++++ 
      Restaurar sesión al cargar la aplicación
  ++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');

    if (storedUser && storedIsAuthenticated === 'true') {
        setUser(JSON.parse(storedUser));
        setIsAuthenticated(true);
    }
}, []);


  /*+++++++++++++++++++++++++++++++++++++++ 
      Funciones para Login y Logout
  ++++++++++++++++++++++++++++++++++++++++++*/
  const login = async (correo: string, password: string): Promise<LoginResponse | null> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_RAILWAY_API_URL}/auth/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ correo, password }),
        });

        if (!response.ok) throw new Error('Login failed');

        const data: LoginResponse = await response.json();
        setUser(data);
        setIsAuthenticated(true);

        // Guardar en localStorage
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('isAuthenticated', 'true');

        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');
    navigate('/');
  };


  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        custodySpaces,
        addCustodySpace,
        assignSpace,
        freeSpace,
        removeCustodySpace,
        getCustodySpaces,
        vehicles,
        addVehicle,
        assignVehicle,
        freeVehicle,
        removeVehicle,
        setVehicles,
        // New product management values
        products,
        setProducts,
        addProduct,
        updateProduct,
        deleteProduct,
        refreshProducts,
        error,
        setError,
        accommodations,
        setAccommodations,
        assignAccommodation,
        unassignAccommodation,
        users,
        setUsers, 
        fetchUserById,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error('useAppContext debe ser utilizado dentro de un AppProvider');
  return context;
};

export default AppContext;