import { Vehicle } from "../../contexts/GlobalStateContext";

export const getVehicles = async (): Promise<Vehicle[]> => {
    try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/vehiculos', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching vehicles: ${response.statusText}`);
        }

        const data = await response.json();

        if (Array.isArray(data)) {
            const vehicles = data.map((item) => {
                // Check if there's a nested user object and it has a user_id
                const hasUser = Boolean(item.user && item.user.user_id);
                
                const vehicle: Vehicle = {
                    id: item.id,
                    marca: item.marca,
                    modelo: item.modelo,
                    patente: item.patente,
                    userId: item.user?.user_id || null,
                    ocupado: hasUser, // Set ocupado based on presence of user
                    user: item.user ? {
                        user_id: item.user.user_id,
                        correo: item.user.correo,
                        nombre: item.user.nombre,
                        apellidop: item.user.apellidop,
                        apellidom: item.user.apellidom,
                        rut: item.user.rut
                    } : null
                };

                console.log('Processed vehicle:', {
                    id: vehicle.id,
                    userId: vehicle.userId,
                    ocupado: vehicle.ocupado,
                    hasUser: Boolean(vehicle.user)
                });

                return vehicle;
            });

            return vehicles;
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        console.error('Error in getVehicles:', error);
        throw error;
    }
};

export default getVehicles;