// QrAPI.tsx
export const getLastUserRecord = async (userId: string) => {
  try {
    const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/registros/all');
    const allRecords = await response.json();
    
    const userRecords = allRecords
      .filter((record: any) => record.user.user_id.toString() === userId)
      .sort((a: any, b: any) => new Date(b.fecha_hora).getTime() - new Date(a.fecha_hora).getTime());
    
    return userRecords[0] || null;
  } catch (error) {
    console.error('Error al obtener último registro:', error);
    throw error;
  }
};

export const registerEntry = async (userId: string, data: { tipo_ingreso: string, userScannerId: number }) => {
  try {
    const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/registros/${userId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al registrar entrada/salida');
    }
    
    return response;
  } catch (error) {
    throw error;
  }
};


export default registerEntry;