//inventorylist.tsx
import React, { useState, useEffect } from 'react';
import { Products, useAppContext } from '../../contexts/GlobalStateContext';
import { createProduct } from '../../apis/inventory/createProduct';
import { useProducts } from '../../apis/inventory/useProducts';
import { getLowStockProducts, LowStockAlert } from '../../../src/components/utils/stockUtils';

interface FormProduct {
  nombre_producto: string;
  tipo: string;
  stock_minimo: number;
  cantidad: number;
  ubicacion: string;
  apto_celiaco: boolean;
  apto_hipertenso: boolean;
  apto_diabetico: boolean;
}

const InventoryList: React.FC = () => {
  const { products, setProducts, refreshProducts } = useAppContext();
  const { updateProductQuantity, deleteProduct, isLoading, error: updateError } = useProducts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [modifiedQuantities, setModifiedQuantities] = useState<Record<number, number>>({});
  const [showQuantityUpdateMessage, setShowQuantityUpdateMessage] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [newProduct, setNewProduct] = useState<FormProduct>({
    nombre_producto: '',
    tipo: '',
    stock_minimo: 0,
    cantidad: 0,
    ubicacion: '',
    apto_celiaco: false,
    apto_hipertenso: false,
    apto_diabetico: false
  });

  const sortedProducts = [...products].sort((a, b) => (a.id || 0) - (b.id || 0));
  const filteredProducts = sortedProducts.filter(product =>
    product.nombre_producto.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.tipo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const loadInitialProducts = async () => {
      try {
        await refreshProducts();
      } catch (error) {
        console.error('Error loading products:', error);
        setError('Error al cargar los productos. Por favor, recargue la página.');
      }
    };

    loadInitialProducts();
  }, [refreshProducts]);

  const handleQuantityChange = (productId: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = Number(event.target.value);
    if (isNaN(newQuantity) || newQuantity < 0) return;
    setModifiedQuantities(prev => ({ ...prev, [productId]: newQuantity }));
  };
  
  const handleUpdateQuantity = async (productId: number) => {
    const newQuantity = modifiedQuantities[productId];
    if (newQuantity === undefined) return;
    try {
      const success = await updateProductQuantity({
        productId,
        newQuantity
      });
      if (success) {
        setProducts(prevProducts =>
          prevProducts.map(p =>
            p.id === productId ? { ...p, cantidad: newQuantity } : p
          )
        );
        setModifiedQuantities(prev => {
          const { [productId]: removed, ...rest } = prev;
          return rest;
        });
        setShowQuantityUpdateMessage(true);
        setTimeout(() => setShowQuantityUpdateMessage(false), 3000);
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setNewProduct({ ...newProduct, [name]: checked });
      return;
    }

    const numericFields = ['stock_minimo', 'cantidad'];
    const newValue = numericFields.includes(name) ? Number(value) : value;
    setNewProduct({ ...newProduct, [name]: newValue });
  };

  const handleAddProduct = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const createdProduct = await createProduct(newProduct);
      await refreshProducts();
      
      setNewProduct({
        nombre_producto: '',
        tipo: '',
        stock_minimo: 0,
        cantidad: 0,
        ubicacion: '',
        apto_celiaco: false,
        apto_hipertenso: false,
        apto_diabetico: false
      });

      setSuccessMessage('Producto agregado exitosamente');
      setTimeout(() => setSuccessMessage(null), 3000);

    } catch (error) {
      setError('Error al crear el producto. Por favor, intente nuevamente.');
      console.error('Error creating product:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const confirmDelete = (id: number) => {
    setSelectedProduct(id);
    setShowModal(true);
  };
  
  const handleDeleteProduct = async () => {
    if (selectedProduct !== null) {
      try {
        const success = await deleteProduct(selectedProduct);
        if (success) {
          setSuccessMessage('Producto eliminado exitosamente');
          await refreshProducts();
        }
        setShowModal(false);
        setSelectedProduct(null);
      } catch (error) {
        setError('Error al eliminar el producto');
        console.error('Error deleting product:', error);
      }
      setTimeout(() => setSuccessMessage(null), 3000);
    }
  };
  
  const closeModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const lowStockProducts = getLowStockProducts(products);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
    <div className="mb-4">
            <input
              type="text"
              placeholder="Buscar productos por nombre o tipo..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
      <h2 className="text-2xl font-semibold mb-4">Gestión de Inventario</h2>
      
      <LowStockAlert lowStockProducts={lowStockProducts} />
      
      {/* Form with error/success messages */}
      <form onSubmit={handleAddProduct} className="bg-white p-4 rounded shadow mb-6">
        <h3 className="text-lg font-semibold mb-4">Agregar Producto</h3>

        {error && (
          <div className="mb-4 p-4 rounded-lg border-l-4 border-red-500 bg-red-50 text-red-700">
            {error}
          </div>
        )}

        {successMessage && (
          <div className="mb-4 p-4 rounded-lg border-l-4 border-green-500 bg-green-50 text-green-700">
            {successMessage}
          </div>
        )}
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="nombre_producto" className="block text-sm font-medium text-gray-700">
              Nombre del Producto
            </label>
            <input
              type="text"
              id="nombre_producto"
              name="nombre_producto"
              placeholder="Ej: Arroz"
              value={newProduct.nombre_producto}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="tipo" className="block text-sm font-medium text-gray-700">
              Tipo de Producto
            </label>
            <input
              type="text"
              id="tipo"
              name="tipo"
              value={newProduct.tipo}
              onChange={handleInputChange}
              placeholder="Ej: Cereal"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="stock_minimo" className="block text-sm font-medium text-gray-700">
              Stock Mínimo
            </label>
            <input
              type="number"
              id="stock_minimo"
              name="stock_minimo"
              value={newProduct.stock_minimo}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="cantidad" className="block text-sm font-medium text-gray-700">
              Cantidad en Existencia
            </label>
            <input
              type="number"
              id="cantidad"
              name="cantidad"
              value={newProduct.cantidad}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div className="sm:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label htmlFor="ubicacion" className="block text-sm font-medium text-gray-700">
                Ubicación en Despensa
              </label>
              <input
                type="text"
                id="ubicacion"
                name="ubicacion"
                value={newProduct.ubicacion}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <h4 className="block text-sm font-medium text-gray-700 mb-2">
                Restricciones Alimentarias
              </h4>
              <div className="space-y-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="apto_celiaco"
                    name="apto_celiaco"
                    checked={newProduct.apto_celiaco}
                    onChange={handleInputChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="apto_celiaco" className="ml-2 block text-sm text-gray-700">
                    Apto Celíaco
                  </label>
                </div>
                
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="apto_diabetico"
                    name="apto_diabetico"
                    checked={newProduct.apto_diabetico}
                    onChange={handleInputChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="apto_diabetico" className="ml-2 block text-sm text-gray-700">
                    Apto Diabético
                  </label>
                </div>
                
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="apto_hipertenso"
                    name="apto_hipertenso"
                    checked={newProduct.apto_hipertenso}
                    onChange={handleInputChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="apto_hipertenso" className="ml-2 block text-sm text-gray-700">
                    Apto Hipertenso
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`mt-4 inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold text-white
            ${isSubmitting 
              ? 'bg-indigo-400 cursor-not-allowed' 
              : 'bg-indigo-600 hover:bg-indigo-500'}`}
        >
          {isSubmitting ? 'Agregando...' : 'Agregar Producto'}
        </button>
      </form>

      {/* Products List Section */}
      <div className="bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-4">Inventario</h3>
        {showQuantityUpdateMessage && (
          <div className="mb-4 p-4 rounded-lg border-l-4 border-green-500 bg-green-50 text-green-700">
            Producto actualizado con éxito
          </div>
        )}
        
        {products.length === 0 ? (
          <p className="text-gray-500">No hay productos en el inventario.</p>
        ) : (
          <>
            {/* Mobile View - Card Grid */}
            <div className="grid grid-cols-1 gap-6 lg:hidden">
              {filteredProducts.map((product: Products) => (
                <div 
                  key={product.id} 
                  className="bg-white border border-gray-200 rounded-lg shadow-sm p-4"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">
                        {product.nombre_producto}
                      </h3>
                      <p className="text-sm text-gray-500">ID: {product.id}</p>
                    </div>
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {product.tipo}
                    </span>
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500">Stock Mínimo</p>
                      <p className="mt-1 text-sm text-gray-900">{product.stock_minimo}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">Ubicación</p>
                      <p className="mt-1 text-sm text-gray-900">{product.ubicacion}</p>
                    </div>
                  </div>

                  {(product.apto_celiaco || product.apto_diabetico || product.apto_hipertenso) && (
                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-500 mb-2">Restricciones Alimentarias</p>
                      <div className="flex flex-wrap gap-2">
                        {product.apto_celiaco && (
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                            Celíaco
                          </span>
                        )}
                        {product.apto_diabetico && (
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                            Diabético
                          </span>
                        )}
                        {product.apto_hipertenso && (
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
                            Hipertenso
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  
                  <div className="mt-4 pt-4 border-t border-gray-200">
                    <p className="text-sm font-medium text-gray-500 mb-2">
                      Cantidad en Existencia
                    </p>
                    <div className="flex items-center justify-center bg-gray-50 p-2 rounded-md">
                    <input
                      type="number"
                      min="0"
                      value={modifiedQuantities[product.id!] ?? product.cantidad}
                      onChange={(e) => handleQuantityChange(product.id!, e)}
                      className="w-20 text-center rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                    </div>
                    <div className="mt-4 flex justify-center space-x-4">
                      {modifiedQuantities[product.id!] !== undefined && (
                        <button
                          onClick={() => handleUpdateQuantity(product.id!)}
                          className="text-indigo-600 font-bold hover:text-indigo-800"
                        >
                          Actualizar
                        </button>
                      )}
                      <button
                        onClick={() => confirmDelete(product.id!)}
                        className="text-red-600 font-bold hover:text-red-800"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Desktop View - Table */}
            <div className="hidden lg:block">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock Mínimo</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cantidad</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ubicación</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Restricciones</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredProducts.map((product: Products) => (
                    <tr key={product.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{product.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.nombre_producto}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.tipo}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.stock_minimo}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <input
                          type="number"
                          min="0"
                          value={modifiedQuantities[product.id!] ?? product.cantidad}
                          onChange={(e) => handleQuantityChange(product.id!, e)}
                          className="w-20 text-center rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        />
                        {modifiedQuantities[product.id!] !== undefined && (
                          <button
                            onClick={() => handleUpdateQuantity(product.id!)}
                            className="ml-2 text-indigo-600 font-bold hover:text-indigo-800"
                          >
                            Actualizar
                          </button>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.ubicacion}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex gap-2">
                          {product.apto_celiaco && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                              Celíaco
                            </span>
                          )}
                          {product.apto_diabetico && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                              Diabético
                            </span>
                          )}
                          {product.apto_hipertenso && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
                              Hipertenso
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() => confirmDelete(product.id!)}
                          className="text-red-600 font-bold hover:text-red-800"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-20 z-40"></div>
          <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-[90%] md:max-w-sm mx-auto text-white">
            <h3 className="text-lg font-semibold mb-4 text-center break-words whitespace-normal">
              ¿Estás seguro de que deseas eliminar este producto?
            </h3>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
                onClick={closeModal}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700"
                onClick={handleDeleteProduct}
              >
                Eliminar
                </button>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default InventoryList;