import { Accommodation, Habitacion, Reserva } from '../../contexts/GlobalStateContext';

export const getAccommodations = async (): Promise<Accommodation[]> => {
    try {
        const [habitacionesResponse, reservasResponse] = await Promise.all([
            fetch('https://agy-estudio-biblico-production.up.railway.app/habitaciones', {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }),
            fetch('https://agy-estudio-biblico-production.up.railway.app/reservas')
        ]);

        if (!habitacionesResponse.ok) {
            throw new Error(`Error fetching rooms: ${habitacionesResponse.statusText}`);
        }
        
        const habitaciones: Habitacion[] = await habitacionesResponse.json();
        const reservas: Reserva[] = await reservasResponse.json();
        console.log('Reservas cargadas:', reservas);
        
        const hospedajesMap = new Map();
        
        habitaciones.forEach((habitacion: Habitacion) => {
            const hospedaje = habitacion.hospedaje;
            
            if (!habitacion.tipoBano || !habitacion.tipoCamas) {
                console.error('Datos faltantes en habitación:', habitacion);
                return;
            }

            if (!hospedajesMap.has(hospedaje.id)) {
                hospedajesMap.set(hospedaje.id, {
                    id: hospedaje.id,
                    nombre: `${hospedaje.nombre} ${hospedaje.apellidoPaterno} ${hospedaje.apellidoMaterno}`,
                    direccion: hospedaje.direccion,
                    rut: hospedaje.rut,
                    iglesia: hospedaje.iglesia,
                    telefono: hospedaje.telefono,
                    casa_o_dpto: hospedaje.esCasa ? 'CASA' : 'DPTO',
                    estacionamiento: hospedaje.estacionamiento,
                    capacidadEstacionamiento: hospedaje.capacidadEstacionamiento,
                    estacionamientoTexto: hospedaje.estacionamiento ? 'Sí' : 'No',
                    ascensor: hospedaje.ascensor ? 'Sí' : 'No',
                    pisoDepartamento: hospedaje.pisoDepartamento || 0,
                    esCasa: hospedaje.esCasa,
                    esDpto: hospedaje.esDpto,
                    habitaciones: [],
                    habitacionesCount: 0,
                    camasCount: 0,
                    disponible: true
                });
            }

            const currentHospedaje = hospedajesMap.get(hospedaje.id);
            const tipoCamas = Array.isArray(habitacion.tipoCamas) ? habitacion.tipoCamas : [];
            
            const reserva = reservas.find((r: Reserva) => {
                console.log('Comparando:', {
                    habitacionId: r.habitacion?.id,
                    currentHabitacionId: habitacion.id,
                    usuarioId: r.usuario?.user_id
                });
                return r.habitacion?.id === habitacion.id;
            });
            
            const habitacionTransformada = {
                id: habitacion.id,
                numero: habitacion.habitacionPiso,
                descripcion: `HABITACIÓN ${habitacion.habitacionPiso}`,
                tipo_bano: habitacion.tipoBano.tipoDeBano,
                camas: habitacion.tipoCamas.map(tc => ({
                    tipo: tc.tipoDeCama,
                    cantidad: habitacion.camaPorHab
                })),
                habitacionPiso: habitacion.habitacionPiso,
                camaPorHab: habitacion.camaPorHab,
                tipoBano: habitacion.tipoBano,
                tipoCamas: habitacion.tipoCamas,
                asignado_a: reserva ? reserva.usuario?.user_id : undefined,
                reserva: reserva ? {
                    id: reserva.id,
                    fechaIngreso: reserva.fechaIngreso,
                    fechaSalida: reserva.fechaSalida,
                    usuarioUserId: reserva.usuario?.user_id,
                    habitacionId: reserva.habitacion?.id
                } : undefined
            };

            currentHospedaje.habitaciones.push(habitacionTransformada);
            currentHospedaje.habitacionesCount = currentHospedaje.habitaciones.length;
            
            const camasEnHabitacion = habitacion.camaPorHab * tipoCamas.length || 0;
            currentHospedaje.camasCount = (currentHospedaje.camasCount || 0) + camasEnHabitacion;
            
            if (reserva) {
                currentHospedaje.disponible = false;
                currentHospedaje.asignado_a = reserva.usuario?.user_id;
            }
        });

        const result = Array.from(hospedajesMap.values());
        return result;

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};