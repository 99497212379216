import { Vehicle } from '../../contexts/GlobalStateContext';

interface UpdateVehicle {
    vehicleId: number;
    idUsuario: number | null;
}

export const updateVehicle = async (data: UpdateVehicle) => {
    try {
        const body = {
            idUsuario: data.idUsuario === null ? 0 : data.idUsuario,
        };

    
        const response = await fetch(
            `https://agy-estudio-biblico-production.up.railway.app/vehiculos/${data.vehicleId}/asignar`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }
        );
    
        if (!response.ok) {
            const errorDetails = await response.text();
            console.error('Server response:', errorDetails);
            throw new Error(`Error updating vehicle: ${errorDetails}`);
        }

        const updatedVehicle = await response.json();
        
        if (data.idUsuario && data.idUsuario !== 0) {
            try {
                const userResponse = await fetch(
                    `https://agy-estudio-biblico-production.up.railway.app/users/${data.idUsuario}`
                );
                
                if (userResponse.ok) {
                    const userData = await userResponse.json();
                    
                    const completeVehicle: Vehicle = {
                        ...updatedVehicle,
                        ocupado: true,
                        userId: userData.user_id,
                        user: {
                            user_id: userData.user_id,
                            correo: userData.correo,
                            nombre: userData.nombre,
                            apellidop: userData.apellidop,
                            apellidom: userData.apellidom,
                            rut: userData.rut
                        }
                    };
                    
                    return completeVehicle;
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }

        return updatedVehicle;
    } catch (error) {
        console.error('Error in updateVehicle:', error);
        throw error;
    }
};

export const releaseVehicle = async (vehicleId: number) => {
    try {
        const response = await fetch(
            `https://agy-estudio-biblico-production.up.railway.app/vehiculos/${vehicleId}/liberar`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Error releasing vehicle: ${errorDetails}`);
        }

        const result = await response.json();
        console.log('Vehicle released successfully:', result);
        
        return {
            ...result,
            ocupado: false,
            userId: null,
            user: null
        };
    } catch (error) {
        console.error('Error releasing vehicle:', error);
        throw error;
    }
};

export const deleteVehicle = async (id: number): Promise<void> => {
    try {
        const response = await fetch(
            `https://agy-estudio-biblico-production.up.railway.app/vehiculos/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error deleting vehicle: ${errorText}`);
        }
    } catch (error) {
        console.error('Error in deleteVehicle:', error);
        throw error;
    }
};

export default { updateVehicle, releaseVehicle, deleteVehicle };