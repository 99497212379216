import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../src/contexts/GlobalStateContext';
import { createMeal } from '../../../src/apis/inventory/createMeals';
import { getMeals, Meal, MealProduct } from '../../apis/inventory/getMeals';
import { getLowStockProducts, LowStockAlert } from '../../../src/components/utils/stockUtils';

const DELIVERY_COUNT_KEY = 'mealDeliveryCount';

interface NewMeal {
    nombre_comida: string;
    fecha: string;
    tipo_comida: 'Desayuno' | 'Desayuno Especial' | 'Almuerzo' | 'Almuerzo Especial' | 'Cena' | 'Cena Especial';
    ingredientes: MealProduct[];
}

const isFullyDelivered = (meal: Meal) => {
    const storedData = localStorage.getItem(DELIVERY_COUNT_KEY);
    if (!storedData || !meal.id) return false;

    const data = JSON.parse(storedData);
    const delivered = data.counts[meal.id] || 0;
    const totalTables = meal.tipo_comida.includes('Especial') ? 1 : 17;
    
    return delivered >= totalTables;
};

const MealsList: React.FC = () => {
    const { products, refreshProducts } = useAppContext();
    const [meals, setMeals] = useState<Meal[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    
    const [newMeal, setNewMeal] = useState<NewMeal>({
        nombre_comida: '',
        fecha: '',
        tipo_comida: 'Desayuno',
        ingredientes: []
    });

    const [selectedProduct, setSelectedProduct] = useState<{
        id_producto: number;
        cantidad: number;
    }>({
        id_producto: 0,
        cantidad: 1
    });

    useEffect(() => {
        loadMeals();
    }, []);

    const loadMeals = async () => {
        try {
            const fetchedMeals = await getMeals();
            setMeals(fetchedMeals);
        } catch (error) {
            setError('Error al cargar los platos');
        }
    };

    const lowStockProducts = getLowStockProducts(products);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewMeal(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleProductSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const productId = Number(e.target.value);
    
        const productExists = products.some(p => p.id === productId);
        if (!productExists) {
            setError('El producto seleccionado no existe.');
            return;
        }
    
        setSelectedProduct(prev => ({
            ...prev,
            id_producto: productId
        }));
    };

    const handleProductQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedProduct(prev => ({
            ...prev,
            cantidad: Number(e.target.value)
        }));
    };

    const addProductToMeal = () => {
        const product = products.find((p) => p.id === selectedProduct.id_producto);
        
        if (!product) {
            setError('El producto seleccionado no existe.');
            return;
        }
    
        if (selectedProduct.cantidad > product.cantidad) {
            setError(
                `Stock insuficiente para el producto ${product.nombre_producto}. Disponible: ${product.cantidad}, requerido: ${selectedProduct.cantidad}.`
            );
            return;
        }
    
        if (selectedProduct.cantidad <= 0) {
            setError('La cantidad debe ser mayor a 0.');
            return;
        }
    
        setNewMeal((prev) => ({
            ...prev,
            ingredientes: [
                ...prev.ingredientes,
                {
                    id_producto: selectedProduct.id_producto,
                    cantidad: selectedProduct.cantidad,
                    nombre_producto: product.nombre_producto
                }
            ]
        }));
    
        setSelectedProduct({
            id_producto: 0,
            cantidad: 1
        });
        setError(null);
    };

    const removeProductFromMeal = (productId: number) => {
        setNewMeal(prev => ({
            ...prev,
            ingredientes: prev.ingredientes.filter(p => p.id_producto !== productId)
        }));
    };

    const validateMealData = (): boolean => {
        const hasInvalidQuantities = newMeal.ingredientes.some(ing => ing.cantidad <= 0);
    
        if (hasInvalidQuantities) {
            setError('Todas las cantidades deben ser mayores a 0.');
            return false;
        }
    
        if (!newMeal.nombre_comida || !newMeal.fecha || !newMeal.tipo_comida) {
            setError('Todos los campos del plato son obligatorios.');
            return false;
        }
    
        return true;
    };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (!validateMealData()) return;

        const insufficientStock = newMeal.ingredientes.some((ingrediente) => {
            const product = products.find((p) => p.id === ingrediente.id_producto);
            return product && ingrediente.cantidad > product.cantidad;
        });
    
        if (insufficientStock) {
            setError('Uno o más ingredientes tienen stock insuficiente.');
            return;
        }
    
        try {
            const isSpecial = newMeal.tipo_comida.includes('Especial');
            const mealToCreate = {
                ...newMeal,
                nombre_comida: isSpecial ? `${newMeal.nombre_comida} Especial` : newMeal.nombre_comida
            };

            await createMeal(mealToCreate);
            await refreshProducts();
            await loadMeals();
    
            setNewMeal({
                nombre_comida: '',
                fecha: '',
                tipo_comida: 'Desayuno',
                ingredientes: []
            });
    
            setSuccess('Plato creado exitosamente');
            setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
            setError('Error al crear el plato');
        }
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h2 className="text-2xl font-semibold mb-4">Gestión de Platos</h2>
            <LowStockAlert lowStockProducts={lowStockProducts} />
            
            {error && (
                <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg">{error}</div>
            )}
     
            {success && (
                <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-lg">{success}</div>
            )}
     
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Nombre del Plato
                        </label>
                        <input
                            type="text"
                            name="nombre_comida"
                            value={newMeal.nombre_comida}
                            onChange={handleInputChange}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                    </div>
     
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Fecha de Servicio
                        </label>
                        <input
                            type="datetime-local"
                            name="fecha"
                            value={newMeal.fecha}
                            onChange={handleInputChange}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                    </div>
     
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Tipo de Comida
                        </label>
                        <select
                            name="tipo_comida"
                            value={newMeal.tipo_comida}
                            onChange={handleInputChange}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        >
                            <optgroup label="Menú Normal">
                                <option value="Desayuno">Desayuno</option>
                                <option value="Almuerzo">Almuerzo</option>
                                <option value="Cena">Cena</option>
                            </optgroup>
                            <optgroup label="Menú Especial">
                                <option value="Desayuno Especial">Desayuno Especial</option>
                                <option value="Almuerzo Especial">Almuerzo Especial</option>
                                <option value="Cena Especial">Cena Especial</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
     
                {/* Sección de Ingredientes */}
                <div className="mb-4">
                    <h3 className="text-lg font-medium mb-2">Agregar Ingredientes</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Producto</label>
                            <select
                                value={selectedProduct.id_producto}
                                onChange={handleProductSelect}
                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            >
                                <option value="0">Seleccionar producto</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>
                                        {product.nombre_producto} (Stock: {product.cantidad})
                                    </option>
                                ))}
                            </select>
                        </div>
     
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad</label>
                            <input
                                type="number"
                                value={selectedProduct.cantidad}
                                onChange={handleProductQuantity}
                                min="1"
                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                        </div>
     
                        <div className="flex items-end">
                            <button
                                type="button"
                                onClick={addProductToMeal}
                                disabled={!selectedProduct.id_producto}
                                className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:bg-gray-400"
                            >
                                Agregar Ingrediente
                            </button>
                        </div>
                    </div>
                </div>
     
                {newMeal.ingredientes.length > 0 && (
                    <div className="mb-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">Ingredientes agregados:</h4>
                        <ul className="space-y-2">
                            {newMeal.ingredientes.map(product => (
                                <li key={product.id_producto} className="flex justify-between items-center bg-gray-50 p-2 rounded">
                                    <span>{product.nombre_producto} - {product.cantidad} unidades</span>
                                    <button
                                        type="button"
                                        onClick={() => removeProductFromMeal(product.id_producto)}
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        Eliminar
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
     
                <button
                    type="submit"
                    disabled={newMeal.ingredientes.length === 0 || error !== null}
                    className={`w-full px-4 py-2 text-white rounded-md ${
                        newMeal.ingredientes.length === 0 || error !== null
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-green-600 hover:bg-green-700'
                    }`}
                >
                    Crear Plato
                </button>
            </form>
     
            {/* Lista de Platos */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg font-medium mb-4">Platos Registrados</h3>
                {meals.length === 0 ? (
                    <p className="text-gray-500">No hay platos registrados.</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {meals
                            .sort((a, b) => {
                                const aDelivered = isFullyDelivered(a);
                                const bDelivered = isFullyDelivered(b);
                                if (aDelivered === bDelivered) {
                                    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
                                }
                                return aDelivered ? 1 : -1;
                            })
                            .map(meal => (
                                <div key={meal.id} 
                                    className={`border rounded-lg p-4 relative ${
                                        isFullyDelivered(meal) ? 'opacity-75 bg-gray-50' : 'bg-white'
                                    }`}
                                >
                                    {isFullyDelivered(meal) && (
                                        <div className="absolute top-2 right-2">
                                            <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                                                Entregado
                                            </span>
                                        </div>
                                    )}
                                    <h4 className="font-medium mb-2">{meal.nombre_comida}</h4>
                                    <p className="text-sm text-gray-600">Fecha: {new Date(meal.fecha).toLocaleString()}</p>
                                    <p className="text-sm text-gray-600">Tipo: {meal.tipo_comida}</p>
                                    <div className="mt-2">
                                        <p className="text-sm font-medium">Ingredientes:</p>
                                        <ul className="text-sm text-gray-600">
                                            {meal.platosProductos?.map((producto) => (
                                                <li key={producto.id_producto}>
                                                    {producto.productos?.nombre_producto || 'Producto no encontrado'}: {producto.cantidad}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MealsList;