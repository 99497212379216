interface CreateReservationParams {
  usuarioId: number;
  habitacionId: number;
  fechaIngreso: string;
  fechaSalida: string;
}

interface Reserva {
  habitacionId: number;
  fechaInicio: string;
  fechaFin: string;
}

interface Habitacion {
  id: number;
  tipoCamaId: number | null;
  capacidad: number;
}

interface ValidacionParams {
  habitacionId: number;
  fechaInicio: string;
  fechaFin: string;
  tipoCamaId: number;
  capacidadRequerida: number;
}

// Función para validar la disponibilidad de una habitación
const validarDisponibilidad = (
  reservas: Reserva[],
  habitaciones: Habitacion[],
  params: ValidacionParams
): { disponible: boolean; mensaje: string } => {
  const { habitacionId, fechaInicio, fechaFin, tipoCamaId, capacidadRequerida } = params;

  // Buscar la habitación por ID
  const habitacion = habitaciones.find((hab) => hab.id === habitacionId);
  if (!habitacion) {
    return { disponible: false, mensaje: "La habitación no existe." };
  }

  // Validar tipo de cama (ignorar si tipoCamaId no está definido)
  if (habitacion.tipoCamaId && habitacion.tipoCamaId !== tipoCamaId) {
    return { disponible: false, mensaje: "El tipo de cama no coincide." };
  }

  // Validar capacidad
  if (habitacion.capacidad < capacidadRequerida) {
    return { disponible: false, mensaje: "La capacidad de la habitación es insuficiente." };
  }

  // Validar disponibilidad en las fechas
  const conflicto = reservas.some((reserva) => {
    return (
      reserva.habitacionId === habitacionId &&
      ((new Date(fechaInicio) >= new Date(reserva.fechaInicio) &&
        new Date(fechaInicio) < new Date(reserva.fechaFin)) ||
        (new Date(fechaFin) > new Date(reserva.fechaInicio) &&
          new Date(fechaFin) <= new Date(reserva.fechaFin)))
    );
  });

  if (conflicto) {
    return { disponible: false, mensaje: "La habitación no está disponible para las fechas seleccionadas." };
  }

  // Todo está validado
  return { disponible: true, mensaje: "La habitación cumple con los requisitos." };
};

// Función para crear una reserva
export const createReservation = async (
  reservas: Reserva[],
  habitaciones: Habitacion[],
  params: CreateReservationParams
) => {
  try {
    // Validar disponibilidad
    const validacion = validarDisponibilidad(reservas, habitaciones, {
      habitacionId: params.habitacionId,
      fechaInicio: params.fechaIngreso,
      fechaFin: params.fechaSalida,
      tipoCamaId: 1, // Cambiar según los requisitos del visitante
      capacidadRequerida: 2, // Ajustar según los requisitos del visitante
    });

    if (!validacion.disponible) {
      throw new Error(validacion.mensaje);
    }

    // Crear la reserva si todo está validado
    const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/reservas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || 'Error al crear la reserva.');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creando reserva:', error);
    throw error;
  }
};

export default createReservation;