// src/pages/RecordsPage.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';

const RecordsPage: React.FC = () => {
  return (
    <div className="p-11 bg-white-50 ml-0">
      <div className="mt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default RecordsPage;