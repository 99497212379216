import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface User {
  user_id: number;
  nombre: string;
  apellidop: string;
  apellidom: string;
}

interface Record {
  id: number;
  tipo_ingreso: string;
  fecha_hora: string;
  usuarioEscaneo: string;
  user: User;
}

const RecordsHistory: React.FC = () => {
  const [records, setRecords] = useState<Record[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/registros/all');
        if (!response.ok) throw new Error('Error al cargar los registros');
        const data = await response.json();
        setRecords(data);
      } catch (error) {
        console.error('Error al cargar registros:', error);
        setError(error instanceof Error ? error.message : 'Error al cargar los registros');
      } finally {
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Fecha no válida';
      }
      return date.toLocaleString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    } catch (error) {
      return 'Fecha no válida';
    }
  };

  const formatUserName = (user: User) => {
    return `${user.nombre} ${user.apellidop} ${user.apellidom}`.trim();
  };

  const formatScannerName = (record: Record) => {
    const nombre = record.usuarioEscaneo || '';
    
    const userMatch = records.find(r => 
      r.user.nombre.toLowerCase() === nombre.toLowerCase()
    )?.user;

    if (userMatch) {
      return `${userMatch.nombre} ${userMatch.apellidop}`.trim();
    }

    return record.usuarioEscaneo || 'No especificado';
  };

  const getTipoIngresoBadge = (tipo: string) => {
    const baseClasses = "px-2 py-1 text-xs font-medium rounded-full";
    if (tipo?.toLowerCase().includes('entrada')) {
      return (
        <span className={`${baseClasses} bg-green-100 text-green-800`}>
          Entrada
        </span>
      );
    } else {
      return (
        <span className={`${baseClasses} bg-red-100 text-red-800`}>
          Salida
        </span>
      );
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen">
      <p className="text-gray-500">Cargando registros...</p>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center min-h-screen">
      <p className="text-red-500">Error: {error}</p>
    </div>
  );

  if (!records || records.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-gray-500">No hay registros disponibles</p>
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Historial de Registros</h1>
          <p className="mt-2 text-sm text-gray-700">
            Lista completa de todos los registros del sistema
          </p>
        </div>
      </div>
      
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                      ID Usuario
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Usuario
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Escaneado por
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tipo de Ingreso
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Fecha y Hora
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {records.map((record) => (
                    <tr key={record.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                        {record.user?.user_id || 'No especificado'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {record.user ? formatUserName(record.user) : 'Usuario no especificado'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatScannerName(record)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {getTipoIngresoBadge(record.tipo_ingreso)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDate(record.fecha_hora)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordsHistory;