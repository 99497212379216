import { useState, useEffect } from 'react';
import { User } from '../../types/User'; // Adjust the path as necessary

const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const [usersResponse, reservasResponse] = await Promise.all([
          fetch('https://agy-estudio-biblico-production.up.railway.app/users'),
          fetch('https://agy-estudio-biblico-production.up.railway.app/reservas')
        ]);

        if (!usersResponse.ok) throw new Error('Error al cargar los usuarios');
        const usersData: User[] = await usersResponse.json();
        const reservasData = await reservasResponse.json();

        // Combinar usuarios con sus reservas
        const usersWithReservas = usersData.map(user => ({
          ...user,
          reserva: reservasData.find(
            (reserva: any) => reserva.usuarioUserId === user.user_id
          )
        }));

        setUsers(usersWithReservas);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Función para obtener un usuario por ID
  const fetchUserById = async (id: number): Promise<User | null> => {
    setLoading(true);
    try {
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${id}`);
      if (!response.ok) throw new Error('Error al obtener el usuario');
      const data: User = await response.json();
      setSelectedUser(data);
      return data;
    } catch (error) {
      setError((error as Error).message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Función para eliminar un usuario
  const deleteUser = async (id: number) => {
    try {
      console.log('Attempting to delete user with ID:', id);
      
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${id}`, {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        if (response.status === 500) {
          // Check if it's the foreign key constraint error
          const responseText = await response.text();
          if (responseText.includes('foreign key constraint')) {
            throw new Error('FOREIGN_KEY_ERROR');
          }
        }
        throw new Error('Error al eliminar el usuario.');
      }
  
      setUsers(users.filter(user => user.user_id !== id));
      return true;
    } catch (error) {
      throw error;
    }
  };

  // Función para editar un usuario
  const editUser = async (id: number, updatedUser: User) => {
    setLoading(true);
    try {
      // Create a new object without the user_id field
      const { user_id, ...userDataWithoutId } = updatedUser;
      
      console.log('Sending update with payload:', JSON.stringify(userDataWithoutId, null, 2));
      
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userDataWithoutId),
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || 'Error al editar el usuario.');
      }
  
      const editedUser = await response.json();
      setUsers(users.map(user => (user.user_id === id ? editedUser : user)));
      return { success: true, data: editedUser };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'No se pudo editar el usuario.';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  return { users, selectedUser, loading, error, fetchUserById, deleteUser, editUser };
};

export default useUsers;
