import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/GlobalStateContext';
import { getVehicles } from '../../apis/vehicles/getVehicles';
import { createVehicle } from '../../apis/vehicles/createVehicle';
import { deleteVehicle } from '../../apis/vehicles/useVehicle';

const VehicleSections: React.FC = () => {
  const navigate = useNavigate();
  const { vehicles, removeVehicle, setVehicles, addVehicle } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<'all' | 'assigned' | 'unassigned'>('all');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [newVehicle, setNewVehicle] = useState({
    marca: '',
    modelo: '',
    patente: '',
  });

  useEffect(() => {
    const loadVehicles = async () => {
      setIsLoading(true);
      try {
        const allVehicles = await getVehicles();
        setVehicles(allVehicles);
      } catch (error) {
        console.error('Error loading vehicles:', error);
        setError('Error al cargar los vehículos. Por favor, recargue la página.');
      } finally {
        setIsLoading(false);
      }
    };

    loadVehicles();
  }, [setVehicles]);

  const filteredVehicles = vehicles.filter((vehicle) => {
    const matchesSearch =
      vehicle.marca.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.modelo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.patente.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter =
      filter === 'all' ||
      (filter === 'assigned' && vehicle.ocupado) ||
      (filter === 'unassigned' && !vehicle.ocupado);

    return matchesSearch && matchesFilter;
  });

  const handleAddVehicle = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const createdVehicle = await createVehicle(newVehicle);
      
      addVehicle({
        ...createdVehicle,
        userId: null,
        ocupado: false
      });

      setNewVehicle({ marca: '', modelo: '', patente: '' });
      
      const allVehicles = await getVehicles();
      setVehicles(allVehicles);
    } catch (error) {
      console.error('Error adding vehicle:', error);
      setError('Error al agregar el vehículo. Por favor, intente nuevamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const confirmDelete = (id: number) => {
    setSelectedVehicle(id);
    setShowModal(true);
  };

  const handleDeleteVehicle = async () => {
    if (selectedVehicle !== null) {
        setIsSubmitting(true);
        try {
            await deleteVehicle(selectedVehicle);
            
            removeVehicle(selectedVehicle);
            
            const allVehicles = await getVehicles();
            setVehicles(allVehicles);
            
            setShowModal(false);
            setSelectedVehicle(null);
        } catch (error) {
            console.error('Error deleting vehicle:', error);
            setError('Error al eliminar el vehículo. Por favor, intente nuevamente.');
        } finally {
            setIsSubmitting(false);
        }
    }
};

const closeModal = () => {
  setShowModal(false);
  setSelectedVehicle(null);
  setError(null);
};

  const renderUserInfo = (vehicle: any) => {
    if (!vehicle.userId || !vehicle.user) return null;
    const fullName = `${vehicle.user.nombre} ${vehicle.user.apellidop} ${vehicle.user.apellidom}`.trim();
    return (
      <div className="mt-2 p-2 bg-gray-50 rounded-md">
        <h4 className="text-sm font-medium text-gray-700">Asignado a:</h4>
        <p className="text-sm text-gray-600">{fullName}</p>
        {vehicle.user.rut && (
          <p className="text-sm text-gray-600">
            <span className="text-gray-500">RUT:</span> {vehicle.user.rut}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-semibold mb-4">Gestión de Vehículos</h2>

      {/* Search input for filtering vehicles */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar vehículo..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      {/* Form for adding new vehicles */}
      <form onSubmit={handleAddVehicle} className="bg-white p-4 rounded shadow mb-6">
        <h3 className="text-lg font-semibold mb-4">Agregar Vehículo</h3>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div>
            <label htmlFor="marca" className="block text-sm font-medium text-gray-700">
              Marca
            </label>
            <input
              type="text"
              id="marca"
              value={newVehicle.marca}
              onChange={(e) => setNewVehicle({ ...newVehicle, marca: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label htmlFor="modelo" className="block text-sm font-medium text-gray-700">
              Modelo
            </label>
            <input
              type="text"
              id="modelo"
              value={newVehicle.modelo}
              onChange={(e) => setNewVehicle({ ...newVehicle, modelo: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label htmlFor="patente" className="block text-sm font-medium text-gray-700">
              Patente
            </label>
            <input
              type="text"
              id="patente"
              value={newVehicle.patente}
              onChange={(e) => setNewVehicle({ ...newVehicle, patente: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              disabled={isSubmitting}
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`mt-4 inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold text-white
            ${isSubmitting 
              ? 'bg-indigo-400 cursor-not-allowed' 
              : 'bg-indigo-600 hover:bg-indigo-500'
            }`}
        >
          {isSubmitting ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Agregando...
            </>
          ) : (
            'Agregar Vehículo'
          )}
        </button>
      </form>

      {/* Vehicle listing section */}
      <div className="bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-4">Listado de Vehículos</h3>

        {/* Filter buttons */}
        <div className="mb-4 flex space-x-4">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'all' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Todos
          </button>
          <button
            onClick={() => setFilter('assigned')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'assigned' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Asignados
          </button>
          <button
            onClick={() => setFilter('unassigned')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'unassigned' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            No Asignados
          </button>
        </div>

        {isLoading ? (
          <p className="text-gray-500">Cargando vehículos...</p>
        ) : filteredVehicles.length === 0 ? (
          <p className="text-gray-500">No se encontraron vehículos.</p>
        ) : (
          <>
            {/* Mobile View */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:hidden">
              {filteredVehicles.map((vehicle) => (
                <div key={vehicle.id} className="bg-white border border-gray-300 p-4 rounded-lg shadow-md">
                  <h3 className="text-lg font-semibold text-gray-900">{vehicle.marca} {vehicle.modelo}</h3>
                  <p className="text-sm text-gray-500">Patente: {vehicle.patente}</p>
                  <p className="text-sm text-gray-500">
                    Estado: {vehicle.ocupado ? 'Asignado' : 'No Asignado'}
                  </p>
                  {vehicle.user && renderUserInfo(vehicle)}
                  <div className="mt-4 flex space-x-4">
                  {vehicle.userId ? (
                    <button
                      onClick={() => navigate('/Vehicles/deliver_vehicle', { 
                        state: { type: 'EntregarVehiculo', vehicleId: vehicle.id } 
                      })}
                      className="text-lime-600 hover:text-lime-900"
                    >
                      Entregar
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => navigate('/Vehicles/assign_vehicle', { 
                          state: { type: 'AsignarVehiculo', vehicleId: vehicle.id } 
                        })}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Asignar
                      </button>
                        <button
                          onClick={() => confirmDelete(vehicle.id)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Eliminar
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Desktop View - Table Layout */}
            <div className="hidden lg:block">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Marca
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Modelo
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Patente
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Usuario Asignado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredVehicles.map((vehicle) => (
                    <tr key={vehicle.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {vehicle.marca}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {vehicle.modelo}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {vehicle.patente}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {vehicle.ocupado ? 'Asignado' : 'No Asignado'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {vehicle.user ? (
                          <>
                            <p className="font-medium">
                              {`${vehicle.user.nombre} ${vehicle.user.apellidop} ${vehicle.user.apellidom}`.trim()}
                            </p>
                            {vehicle.user.rut && (
                              <p className="text-xs text-gray-400 mt-1">
                                RUT: {vehicle.user.rut}
                              </p>
                            )}
                          </>
                        ) : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {vehicle.userId ? (
                        <button
                          onClick={() => navigate('/Vehicles/deliver_vehicle', { 
                            state: { type: 'EntregarVehiculo', vehicleId: vehicle.id } 
                          })}
                          className="text-lime-600 hover:text-lime-900"
                        >
                          Entregar
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => navigate('/Vehicles/assign_vehicle', { 
                              state: { type: 'AsignarVehiculo', vehicleId: vehicle.id } 
                            })}
                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                          >
                            Asignar
                          </button>
                            <button
                              onClick={() => confirmDelete(vehicle.id)}
                              className="text-red-600 hover:text-red-800"
                            >
                              Eliminar
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-20 z-40"></div>
          <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-[90%] md:max-w-sm mx-auto text-white">
            <h3 className="text-lg font-semibold mb-4 text-center break-words whitespace-normal">
              ¿Estás seguro de que deseas eliminar este vehículo?
            </h3>
            
            {error && (
              <div className="mb-4 p-3 bg-red-800 border border-red-600 text-white rounded">
                {error}
              </div>
            )}

            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
                onClick={closeModal}
                disabled={isSubmitting}
              >
                Cancelar
              </button>
              <button
                className={`px-4 py-2 font-bold rounded-md 
                  ${isSubmitting 
                    ? 'bg-red-400 cursor-not-allowed' 
                    : 'bg-red-600 hover:bg-red-700'
                  }`}
                onClick={handleDeleteVehicle}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Eliminando...
                  </div>
                ) : (
                  'Eliminar'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleSections;
