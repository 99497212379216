import { Vehicle } from "../../contexts/GlobalStateContext";

interface CreateVehicleData {
    marca: string;
    modelo: string;
    patente: string;
}

export const createVehicle = async (vehicleData: CreateVehicleData): Promise<Vehicle> => {
    try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/vehiculos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                marca: vehicleData.marca,
                modelo: vehicleData.modelo,
                patente: vehicleData.patente,
                ocupado: false
            }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('API Error Response:', errorText);
            throw new Error(`Error creating vehicle: ${errorText}`);
        }

        const data = await response.json();
        
        return {
            id: data.id,
            marca: data.marca,
            modelo: data.modelo,
            patente: data.patente,
            userId: data.user_id || null,
            ocupado: data.ocupado || false,
            user: data.user || null
        };
    } catch (error) {
        console.error('Error in createVehicle:', error);
        throw error;
    }
};

export default createVehicle;