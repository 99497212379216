//QrOptionsModal.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

const QrOptionsModal: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
      {/* Modal content */}
      <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-sm mx-auto text-white">
        <h3 className="text-lg font-semibold mb-4">Selecciona Acción</h3>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
            //onClick={onClose}
            onClick={() => {
                navigate('/Records/history');
              }}
          >
            Cancelar
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600"
            onClick={() => {
              const salida = 'Salida';
              navigate('/ScanQr', { state: { type: salida } });
            }}
          >
            Escanear Salida
          </button>
          <button
            className="px-4 py-2 bg-green-500 text-white font-bold rounded-md hover:bg-green-600"
            onClick={() => {
              const entrada = 'Entrada';
              navigate('/ScanQr', { state: { type: entrada } });
            }}
          >
            Escanear Entrada
          </button>
        </div>
      </div>
    </div>
  );
};

export default QrOptionsModal;
