import { Habitacion } from '../../contexts/GlobalStateContext';

export const assignAccommodation = async (
  payload: { accommodationId: number; visitantId: number },
  habitaciones: Habitacion[]
) => {
  const { accommodationId, visitantId } = payload;

  try {
    const habitacionValida = habitaciones.find((hab) => hab.id === accommodationId);
    if (!habitacionValida) {
      throw new Error(`La habitación con ID ${accommodationId} no existe en el sistema.`);
    }

    const currentDate = new Date().toISOString().split('T')[0];
    const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/reservas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usuarioId: visitantId,
        habitacionId: accommodationId,
        fechaIngreso: currentDate,
        fechaSalida: currentDate,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error asignando alojamiento: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error asignando alojamiento:', error);
    throw error;
  }
};

export const unassignAccommodation = async (accommodationId: number) => {
  try {
    const reservationsResponse = await fetch('https://agy-estudio-biblico-production.up.railway.app/reservas');
    if (!reservationsResponse.ok) {
      throw new Error('Error obteniendo reservas');
    }

    const reservations = await reservationsResponse.json();
    const reservation = reservations.find((r: any) => r.habitacionId === accommodationId);

    if (reservation) {
      const response = await fetch(
        `https://agy-estudio-biblico-production.up.railway.app/reservas/${reservation.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Error liberando el alojamiento');
      }
    }

    return true;
  } catch (error) {
    console.error('Error liberando alojamiento:', error);
    throw error;
  }
};

export default {
  assignAccommodation,
  unassignAccommodation,
};