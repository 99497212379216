import { Meal } from './getMeals';

export interface PlatoProducto {
    id_producto: number;
    cantidad: number;
    productos: {
        nombre_producto: string;
        cantidad: number;
    };
}

export interface MealDelivery {
    id: number;
    nombre_comida: string;
    fecha: string;
    tipo_comida: 'Desayuno' | 'Desayuno Especial' | 'Almuerzo' | 'Almuerzo Especial' | 'Cena' | 'Cena Especial';
    menu_type: 'normal' | 'especial';
    platosProductos: PlatoProducto[];
    mesas_pendientes: number;
}

export interface DeliveryPayload {
    id?: number;
    mesas: number;
    fechaEntrega: string;
    platoId: number;
}

export const getTodaysMeals = async (): Promise<MealDelivery[]> => {
    try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/platos');
        if (!response.ok) {
            throw new Error('Error al obtener los platos');
        }
        
        const meals = await response.json();
        
        const today = new Date().toISOString().split('T')[0];
        const todaysMeals = meals
            .filter((meal: Meal) => meal.fecha.startsWith(today))
            .map((meal: Meal) => ({
                ...meal,
                id: meal.id!,
                menu_type: determineMenuType(meal.tipo_comida),
                mesas_pendientes: determineInitialTables(meal.tipo_comida)
            }));

        return todaysMeals;
    } catch (error) {
        console.error('Error fetching today\'s meals:', error);
        throw error;
    }
};

export const deliverMeal = async (platoId: number): Promise<boolean> => {
    try {
        const payload: DeliveryPayload = {
            mesas: 1,
            fechaEntrega: new Date().toISOString(),
            platoId: platoId
        };

        const response = await fetch(
            `https://agy-estudio-biblico-production.up.railway.app/menus/${platoId}/entregar`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            }
        );

        if (!response.ok) {
            const errorText = await response.text();
            try {
                const errorData = JSON.parse(errorText);
                throw new Error(`Error al registrar la entrega del plato: ${errorData.message || response.statusText}`);
            } catch (jsonError) {
                throw new Error(`Error al registrar la entrega del plato: ${errorText || response.statusText}`);
            }
        }

        return true;
    } catch (error) {
        console.error('Error delivering meal:', error);
        throw error;
    }
};

const determineMenuType = (tipoComida: string): 'normal' | 'especial' => {
    return tipoComida.includes('Especial') ? 'especial' : 'normal';
};

const determineInitialTables = (tipoComida: string): number => {
    return tipoComida.includes('Especial') ? 1 : 17;
};