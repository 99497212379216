import React from 'react';
import { useNavigate } from 'react-router-dom';
import QrReader from '../QrReader';

const AssignVehicle: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-50 relative">
      {/* Header with Back Button */}
      <div className="flex items-center justify-center w-full mb-8">
        <button
          onClick={() => navigate('/Vehicles/list')}
          className="absolute top-0 left-4 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-500"
        >
          Volver
        </button>
        <h2 className="text-xl font-semibold text-center">Asignación de Vehículo</h2>
      </div>

      <div className="text-center mb-8">
        <p className="text-gray-600">
          Escanee el código QR del usuario para asignar el vehículo.
        </p>
      </div>

      {/* QR Reader */}
      <div className="w-full max-w-sm">
        <QrReader onScan={(data) => console.log(data)} />
      </div>
    </div>
  );
};

export default AssignVehicle;