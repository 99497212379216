import React, { useState, useMemo } from 'react';
import { Home } from 'lucide-react';
import { Accommodation, useAppContext } from '../../contexts/GlobalStateContext';
import { RoomManagement } from './RoomManagement';

const ITEMS_PER_PAGE = 10;

export const AccommodationList: React.FC = () => {
  const { accommodations } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAccommodation, setSelectedAccommodation] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    tipo: 'todos',
    disponibilidad: 'todos',
    estacionamiento: 'todos'
  });
  
  const getAccommodationStatus = (accommodation: Accommodation) => {
    const totalRooms = accommodation.habitaciones.length;
    const availableRooms = accommodation.habitaciones.filter(
      hab => !hab.asignado_a && !hab.reserva
    ).length;
    const occupiedRooms = totalRooms - availableRooms;
  
    if (availableRooms === 0) {
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          Ocupado
        </span>
      );
    }
  
    return (
      <div className="flex flex-col space-y-1">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {availableRooms} hab. disponibles
        </span>
        {occupiedRooms > 0 && (
          <span className="px-2 inline-flex text-xs leading-5 text-gray-500 text-sm">
            ({occupiedRooms} ocupadas)
          </span>
        )}
      </div>
    );
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Filtrado
  const filteredAccommodations = useMemo(() => {
    return accommodations
      .filter(acc => {
        const matchesSearch = 
          acc.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
          acc.direccion.toLowerCase().includes(searchTerm.toLowerCase()) ||
          acc.iglesia.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesTipo = filters.tipo === 'todos' || acc.casa_o_dpto === filters.tipo;
        const matchesDisponibilidad = filters.disponibilidad === 'todos' || 
          (filters.disponibilidad === 'disponible' ? acc.disponible : !acc.disponible);
        const matchesEstacionamiento = filters.estacionamiento === 'todos' || 
          (filters.estacionamiento === 'con' ? acc.estacionamiento : !acc.estacionamiento);

        return matchesSearch && matchesTipo && matchesDisponibilidad && matchesEstacionamiento;
      });
  }, [accommodations, searchTerm, filters]);

  // Paginación
  const totalPages = Math.ceil(filteredAccommodations.length / ITEMS_PER_PAGE);
  const paginatedAccommodations = filteredAccommodations.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleViewRooms = (accommodationId: number) => {
    setSelectedAccommodation(accommodationId);
  };

  return (
    <div className="bg-white rounded-lg shadow">
      {showSuccessMessage && (
        <div className="p-4 bg-green-50 border border-green-200 rounded-md m-4">
          <p className="text-green-700">{successMessage}</p>
        </div>
      )}

      <div className="p-4 space-y-4">
        <input
          type="text"
          placeholder="Buscar hospedaje..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        
        <div className="flex space-x-4">
          <select
            value={filters.tipo}
            onChange={(e) => setFilters(prev => ({ ...prev, tipo: e.target.value }))}
            className="border rounded-md px-3 py-2"
          >
            <option value="todos">Todos los tipos</option>
            <option value="CASA">Casa</option>
            <option value="DPTO">Departamento</option>
          </select>

          <select
            value={filters.disponibilidad}
            onChange={(e) => setFilters(prev => ({ ...prev, disponibilidad: e.target.value }))}
            className="border rounded-md px-3 py-2"
          >
            <option value="todos">Toda disponibilidad</option>
            <option value="disponible">Disponible</option>
            <option value="ocupado">Ocupado</option>
          </select>

          <select
            value={filters.estacionamiento}
            onChange={(e) => setFilters(prev => ({ ...prev, estacionamiento: e.target.value }))}
            className="border rounded-md px-3 py-2"
          >
            <option value="todos">Estacionamiento</option>
            <option value="con">Con estacionamiento</option>
            <option value="sin">Sin estacionamiento</option>
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nombre</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Dirección</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tipo</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Iglesia</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Habitaciones</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Estado</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredAccommodations.map((accommodation) => (
              <tr key={accommodation.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {accommodation.nombre}
                  </div>
                  <div className="text-sm text-gray-500">
                    {accommodation.rut}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">{accommodation.direccion}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    accommodation.casa_o_dpto === 'CASA' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-blue-100 text-blue-800'
                  }`}>
                    {accommodation.casa_o_dpto}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {accommodation.iglesia}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {accommodation.habitacionesCount} hab / {accommodation.camasCount} camas
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {getAccommodationStatus(accommodation)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button 
                    onClick={() => handleViewRooms(accommodation.id)}
                    className="text-indigo-600 hover:text-indigo-900"
                    title="Ver habitaciones"
                  >
                    <Home className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {filteredAccommodations.length === 0 && (
          <div className="text-center py-4 text-gray-500">
            No se encontraron hospedajes
          </div>
        )}
      </div>

      {selectedAccommodation && (
        <RoomManagement 
          accommodation={accommodations.find(acc => acc.id === selectedAccommodation)!}
          onClose={() => setSelectedAccommodation(null)}
        />
      )}
    </div>
  );
};

export default AccommodationList;