// src/services/validations/AccommodationValidation.ts

import { Accommodation } from '../../contexts/GlobalStateContext';

interface User {
  id: string;
  nombre: string;
  apellidop: string;
  apellidom: string;
  iglesia: string;
  asiste_esposa: boolean;
  pais: string;
  acreditado: boolean;
  rut: string;
  vehiculoPropio: boolean;
  esposa?: {
    nombre: string;
    apellidop: string;
    apellidom: string;
    rut: string;
  };
}

interface ValidationRule {
  validate: (accommodation: Accommodation, user: User) => boolean;
  errorMessage: string;
}

export class AccommodationValidationService {
  private rules: ValidationRule[] = [
    {
      validate: (acc, user) => !user.vehiculoPropio || (user.vehiculoPropio && acc.estacionamiento && acc.capacidadEstacionamiento > 0),
      errorMessage: 'El hospedaje no tiene estacionamiento disponible'
    },
    {
      validate: (acc, user) => !user.asiste_esposa || acc.habitaciones.some(hab => 
        hab.tipoCamas.some(tc => 
          ['matrimonial', 'queen', 'king'].includes(tc.tipoDeCama.toLowerCase())
        )
      ),
      errorMessage: 'No hay habitaciones adecuadas para parejas'
    },
    {
      validate: (acc) => !acc.esDpto || acc.pisoDepartamento <= 3 || acc.ascensor === 'Sí',
      errorMessage: 'El departamento está en un piso alto y no tiene ascensor'
    }
  ];

  validateAccommodation(accommodation: Accommodation, user: User): { isValid: boolean; errors: string[] } {
    const errors = this.rules
      .filter(rule => !rule.validate(accommodation, user))
      .map(rule => rule.errorMessage);

    return {
      isValid: errors.length === 0,
      errors
    };
  }
}

export const accommodationValidationService = new AccommodationValidationService();