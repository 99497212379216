import React, { useState } from 'react';
import { Accommodation } from '../../contexts/GlobalStateContext';
import { createReservation } from '../../apis/accommodations/createReservation';

interface AccommodationInfoCardProps {
  accommodation: Accommodation | null;
  onClose: () => void;
  onAssign: (selectedRoomId: number | null) => void;
  visitantId: number;
  reservas: any[]; // Lista de reservas existentes
  habitaciones: any[]; // Lista de habitaciones disponibles
  readOnly?: boolean;
}

export const AccommodationInfoCard: React.FC<AccommodationInfoCardProps> = ({
  accommodation,
  onClose,
  onAssign,
  visitantId,
  reservas,
  habitaciones,
  readOnly = false,
}) => {
  const [selectedRoom, setSelectedRoom] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleRoomClick = (roomId: number, isAvailable: boolean) => {
    const habitacion = accommodation?.habitaciones.find(hab => hab.id === roomId);
    if (!habitacion) return;

    if (habitacion.asignado_a === visitantId) {
      alert('Esta habitación ya está asignada a ti.');
      return;
    }
    
    if (!isAvailable) {
      alert('Esta habitación no está disponible.');
      return;
    }
    
    setSelectedRoom(roomId === selectedRoom ? null : roomId);
  };  

  const isRoomAvailable = (habitacionId: number) => {
    const isReserved = reservas.some((reserva) => reserva.habitacionId === habitacionId);
    const isAssigned = accommodation?.habitaciones.some(
      hab => hab.id === habitacionId && hab.asignado_a
    );
    return !isReserved && !isAssigned;
  };

  const confirmAssign = async () => {
    if (!accommodation || !selectedRoom) return;

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const habitacion = accommodation.habitaciones.find((hab) => hab.id === selectedRoom);
      if (!habitacion) {
        throw new Error('No se pudo encontrar la habitación seleccionada.');
      }

      const currentDate = new Date().toISOString().split('T')[0];

      await createReservation(reservas, habitaciones, {
        usuarioId: visitantId,
        habitacionId: habitacion.id,
        fechaIngreso: currentDate,
        fechaSalida: currentDate,
      });

      setSuccessMessage('Alojamiento asignado exitosamente');
      setTimeout(() => {
        setSuccessMessage(null);
        onAssign(selectedRoom);
      }, 2000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error al asignar alojamiento');
    } finally {
      setLoading(false);
    }
  };

  if (!accommodation) return null;

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
      {successMessage && (
        <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-md">
          <p className="text-green-700">{successMessage}</p>
        </div>
      )}

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="grid grid-cols-2 gap-x-12 gap-y-6">
        <div>
          <p className="text-sm text-gray-600">Nombre del responsable</p>
          <p className="font-medium">{accommodation.nombre}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Dirección</p>
          <p className="font-medium">{accommodation.direccion}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">RUT</p>
          <p className="font-medium">{accommodation.rut}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Tipo de alojamiento</p>
          <p className="font-medium">{accommodation.casa_o_dpto}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Iglesia</p>
          <p className="font-medium">{accommodation.iglesia}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Estacionamiento</p>
          <p className="font-medium">{accommodation.estacionamientoTexto}</p>
        </div>
      </div>

      <div className="mt-6">
        <h3 className="font-semibold mb-4">Habitaciones disponibles ({accommodation.habitaciones.length})</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {accommodation.habitaciones.map((hab) => {
            const isAvailable = isRoomAvailable(hab.id);
            const isSelected = selectedRoom === hab.id;
            const isAssignedToCurrentUser = hab.asignado_a === visitantId;

            return (
              <div
                key={hab.id}
                onClick={() => isAvailable && handleRoomClick(hab.id, true)}
                className={`p-4 rounded-lg cursor-pointer ${
                  isAssignedToCurrentUser
                    ? 'border-4 border-green-500 bg-green-50'
                    : isAvailable
                    ? isSelected
                      ? 'border-4 border-blue-500'
                      : 'border border-gray-200 hover:border-blue-300'
                    : 'border border-gray-200 bg-gray-100 cursor-not-allowed'
                }`}
              >
                <p className="font-medium mb-2">{`Habitación ${hab.numero}`}</p>
                <p className="text-sm text-gray-600 mb-2">
                  <span className="font-semibold">Baño:</span> {hab.tipoBano.tipoDeBano}
                </p>
                {hab.tipoCamas.map((tc, index) => (
                  <p key={`${hab.id}-${index}`} className="text-sm text-gray-600">
                    <span className="font-semibold">Cama {index + 1}:</span> {tc.tipoDeCama}
                  </p>
                ))}
                <p className="text-sm text-gray-600 mt-2">
                  <span className="font-semibold">Piso:</span> {hab.habitacionPiso}
                </p>
                {isAssignedToCurrentUser && (
                  <p className="text-sm text-green-600 mt-2 font-medium">
                    Asignada a ti
                  </p>
                )}
                {!isAvailable && !isAssignedToCurrentUser && (
                  <p className="text-sm text-red-600 mt-2 font-medium">
                    No disponible
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {!readOnly && (
        <button
          onClick={confirmAssign}
          disabled={!selectedRoom || loading}
          className={`mt-6 px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded ${
            !selectedRoom || loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-700'
          }`}
        >
          {loading ? 'Asignando...' : 'Asignar alojamiento'}
        </button>
      )}
    </div>
  );
};

export default AccommodationInfoCard;