import React, { useState } from 'react';
import { X } from 'lucide-react';
import Papa from 'papaparse';
import { useAppContext, Accommodation } from '../../contexts/GlobalStateContext';

interface CSVUploaderProps {
  onClose: () => void;
  onError: (message: string) => void;
}

type CSVRow = {
  nombre: string;
  direccion: string;
  rut: string;
  iglesia: string;
  telefono: string;
  esCasa: string;
  estacionamiento: string;
  capacidadEstacionamiento: string;
  ascensor: string;
  comuna: string;
  sector: string;
  local: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
};

const CSVUploader: React.FC<CSVUploaderProps> = ({ onClose, onError }) => {
  const [loading, setLoading] = useState(false);
  const { setAccommodations } = useAppContext();  // En lugar de addAccommodation

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);

    Papa.parse<CSVRow>(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results: Papa.ParseResult<CSVRow>) => {
        try {
          if (results.errors.length > 0) {
            throw new Error('Error al procesar el archivo CSV');
          }

          const newAccommodations = results.data.map(row => {
            const isCasa = row.esCasa?.toLowerCase() === 'true';
            return {
              id: 0,
              nombre: row.nombre || '',
              apellidoPaterno: row.apellidoPaterno || '',
              apellidoMaterno: row.apellidoMaterno || '',
              direccion: row.direccion || '',
              rut: row.rut || '',
              iglesia: row.iglesia || '',
              telefono: row.telefono || '',
              comuna: row.comuna || '',
              sector: row.sector || '',
              local: row.local || '',
              casa_o_dpto: isCasa ? 'CASA' as const : 'DPTO' as const,
              esCasa: isCasa,
              esDpto: !isCasa,
              estacionamiento: row.estacionamiento?.toLowerCase() === 'true',
              capacidadEstacionamiento: parseInt(row.capacidadEstacionamiento) || 0,
              estacionamientoTexto: row.estacionamiento?.toLowerCase() === 'true' ? 'Sí' : 'No',
              ascensor: row.ascensor?.toLowerCase() === 'true' ? 'Sí' : 'No',
              pisoDepartamento: 0,
              habitaciones: [],
              habitacionesCount: 0,
              camasCount: 0,
              disponible: true
            };
          });

          setAccommodations(prev => [...prev, ...newAccommodations]);
          onClose();
        } catch (error) {
          onError(error instanceof Error ? error.message : 'Error al procesar el archivo');
        } finally {
          setLoading(false);
        }
      },
      error: (error: Error) => {
        onError('Error al leer el archivo: ' + error.message);
        setLoading(false);
      }
    });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-semibold">Cargar Hospedajes desde CSV</h3>
        <button 
          onClick={onClose}
          className="text-gray-400 hover:text-gray-600"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="mt-4">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          disabled={loading}
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
        />
        <p className="mt-2 text-sm text-gray-500">
          El archivo CSV debe contener las columnas: nombre, direccion, rut, iglesia, telefono, esCasa, estacionamiento, capacidadEstacionamiento, ascensor, comuna, sector, local, apellidoPaterno, apellidoMaterno
        </p>
      </div>

      {loading && (
        <p className="mt-4 text-sm text-gray-600">Procesando archivo...</p>
      )}
    </div>
  );
};

export default CSVUploader;