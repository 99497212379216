import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserGroupIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { CogIcon } from 'lucide-react';

const accommodationOptions = [
    { name: 'Listado de Visitantes', href: 'visitants', icon: UserGroupIcon },
    { name: 'Gestionar Hospedaje', href: 'manage', icon: BuildingOfficeIcon },
    { name: 'Configuración de Hospedajes', href: 'settings', icon: CogIcon },
    ];

    const AccommodationPage: React.FC = () => {
    return (
        <div className="p-11 bg-white-50 min-h-screen">
        <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Alojamientos</h1>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {accommodationOptions.map((option) => (
                <Link
                key={option.name}
                to={option.href}
                className="flex items-center gap-x-2.5 p-4 px-6 text-sm font-semibold text-gray-900 hover:bg-gray-100 sm:justify-center rounded-lg border border-gray-200"
                >
                <option.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                {option.name}
                </Link>
            ))}
            </div>
        </div>
        <div className="mt-8">
            <Outlet />
        </div>
        </div>
    );
};

export default AccommodationPage;