export interface Reserva {
  habitacionId: number;
  fechaInicio: string;
  fechaFin: string;
}

export const getReservas = async (): Promise<Reserva[]> => {
  try {
    const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/reservas');
    if (!response.ok) throw new Error('Error al cargar reservas');
    return await response.json();
  } catch (error) {
    console.error('Error al cargar reservas:', error);
    throw error;
  }
};
