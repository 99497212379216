// Import the Product interface from your GlobalStateContext
import { Products } from '../../contexts/GlobalStateContext';

export const createProduct = async (product: Omit<Products, 'id'>) => {
    try {
        // Format the data to match the server's expected schema
        const productData = {
            nombre_producto: product.nombre_producto,
            tipo: product.tipo,
            stock_minimo: product.stock_minimo,
            cantidad: product.cantidad,
            ubicacion: product.ubicacion,
            apto_celiaco: product.apto_celiaco,
            apto_hipertenso: product.apto_hipertenso,
            apto_diabetico: product.apto_diabetico
        };

        // Make the POST request to the server
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/productos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(productData),
        });

        if (!response.ok) {
            throw new Error(`Error creating product: ${response.statusText}`);
        }

        // Parse and return the created product data
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export default createProduct;