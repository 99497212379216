import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./QrStyles.css";
import { Scanner, IDetectedBarcode, IScannerClassNames } from "@yudiel/react-qr-scanner";
import QrFrame from "../assets/qr-frame.svg";
import { registerEntry, getLastUserRecord } from "../../src/apis/users/QrAPI";
import { updateCustody, releaseCustody } from "../../src/apis/custody/useCustody";
import { updateVehicle, releaseVehicle } from "../../src/apis/vehicles/useVehicle";
import { useAppContext } from "../../src/contexts/GlobalStateContext";

interface QrReaderProps {
  onScan: (data: string) => void;
}

const QrReader: React.FC<QrReaderProps> = ({ onScan }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { type, spaceId, vehicleId } = location.state || { 
    type: "Unknown", 
    spaceId: null, 
    vehicleId: null 
  };
  const { 
    user,
    assignSpace, 
    freeSpace, 
    assignVehicle, 
    freeVehicle 
  } = useAppContext();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const showAlert = (message: string) => {
    setAlertMessage(message);
  };

  const closeAlert = () => {
    setAlertMessage(null);
    switch (type) {
      case "AsignarEspacio":
      case "EntregarEspacio":
        navigate("/Custody/create_section");
        break;
      case "AsignarVehiculo":
      case "EntregarVehiculo":
        navigate("/Vehicles/list");
        break;
      case "Entrada":
      case "Salida":
        if (alertMessage?.includes("Debe registrar una entrada")) {
          navigate("/ScanQrModal");
        } else {
          navigate("/ScanQrModal");
        }
        break;
      default:
        navigate("/Records/history");
    }
  };

  const handleScan = async (data: string) => {
    if (isProcessing) return;
    setIsProcessing(true);
  
    try {
      const parsedData = JSON.parse(data);
      const userId = parsedData.data;
  
      if (isNaN(userId)) {
        showAlert("El código QR no contiene un ID de usuario válido.");
        return;
      }

      switch (type) {
        case "AsignarEspacio":
          if (spaceId) {
            const response = await updateCustody({
              spaceId: spaceId,
              idUsuario: userId,
            });
            if (response) {
              showAlert("Espacio asignado correctamente.");
              assignSpace(spaceId);
            } else {
              showAlert("Error al asignar el espacio.");
            }
          }
          break;

        case "EntregarEspacio":
          if (spaceId) {
            const response = await releaseCustody(spaceId);
            if (response) {
              showAlert("Espacio liberado correctamente.");
              freeSpace(spaceId);
            } else {
              showAlert("Error al liberar el espacio.");
            }
          }
          break;

        case "AsignarVehiculo":
          if (vehicleId) {
            const response = await updateVehicle({
              vehicleId: vehicleId,
              idUsuario: userId,
            });
            if (response) {
              showAlert("Vehículo asignado correctamente.");
              assignVehicle(vehicleId);
            } else {
              showAlert("Error al asignar el vehículo.");
            }
          }
          break;

        case "EntregarVehiculo":
          if (vehicleId) {
            const response = await releaseVehicle(vehicleId);
            if (response) {
              showAlert("Vehículo liberado correctamente.");
              freeVehicle(vehicleId);
            } else {
              showAlert("Error al liberar el vehículo.");
            }
          }
          break;

        case "Entrada":
        case "Salida":
          try {
            const lastRecord = await getLastUserRecord(userId.toString());
            
            if (type === "Entrada" && lastRecord?.tipo_ingreso === "Entrada") {
              showAlert("Usuario no registra salida del recinto, no se puede registrar una Entrada");
              return;
            }
            
            if (type === "Salida" && (!lastRecord || lastRecord.tipo_ingreso === "Salida")) {
              showAlert("Debe registrar una entrada antes de registrar una salida");
              return;
            }

            if (!user?.user.user_id) {
              showAlert("Error: No hay usuario autenticado para realizar el registro");
              return;
            }

            const response = await registerEntry(userId.toString(), { 
              tipo_ingreso: type,
              userScannerId: user.user.user_id 
            });
            
            if (response.ok) {
              showAlert(`Registro de ${type.toLowerCase()} exitoso.`);
            } else {
              const errorData = await response.json();
              showAlert(errorData.message || "Error al registrar. Inténtalo nuevamente.");
            }
          } catch (error) {
            showAlert(error instanceof Error ? error.message : "Error al procesar el registro");
          }
          break;

        default:
          showAlert("Acción desconocida.");
      }
    } catch (error) {
      console.error("Error al procesar el escaneo:", error);
      showAlert("Error al procesar el escaneo. Inténtalo nuevamente.");
    } finally {
      setIsProcessing(false);
    }
  };
  
  return (
    <div className="qr-reader">
      <p className="qr-type">Tipo de Escaneo: {type}</p>
      {(type.includes("Espacio") || type.includes("Vehiculo")) && (
        <p className="qr-type">
          Id: {type.includes("Espacio") ? spaceId : vehicleId}
        </p>
      )}

      <Scanner
        onScan={(detectedCodes: IDetectedBarcode[]) => {
          if (detectedCodes.length > 0) {
            handleScan(detectedCodes[0].rawValue);
          }
        }}
        constraints={{ facingMode: "environment" }}
        classNames={{
          container: "qr-scanner-container",
          video: "qr-scanner-video",
          canvas: "qr-scanner-canvas",
        } as IScannerClassNames}
      />

      <div className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>

      {alertMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
          <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-sm mx-auto text-white">
            <h3 className="text-lg font-semibold mb-4">Alerta</h3>
            <p className="mb-6">{alertMessage}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={closeAlert}
                className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrReader;