//viewusers.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUsers from '../../../apis/users/useUsers';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

const ViewUsers: React.FC = () => {
    const navigate = useNavigate();
    const { users, deleteUser } = useUsers(); // `deleteUser` to delete a user
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Filtering users
    const filteredUsers = users.filter(user =>
        user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.apellidop?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.correo.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    const currentUsers = filteredUsers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleAddUserRedirect = () => {
        navigate('/Users/Add');
    };

    const handleEditRedirect = (id: number) => {
        navigate(`/edit_user/${id}`);
    };

    const handleDetailRedirect = (id: number) => {
        navigate(`/detail_user/${id}`);
    };

    const handleDeleteUser = async (id: number) => {
        if (window.confirm("¿Estás seguro de que deseas eliminar este usuario?")) {
            try {
                await deleteUser(id);
                // Success message
                alert('Usuario eliminado exitosamente');
            } catch (error) {
                if (error instanceof Error && error.message === 'FOREIGN_KEY_ERROR') {
                    alert('No se puede eliminar este usuario porque tiene registros asociados.');
                } else {
                    alert('No se puede eliminar este usuario porque tiene registros asociados.');
                }
                console.error('Error:', error);
            }
        }
    };

    // Function to render page numbers
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5;
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (currentPage <= 3) {
            endPage = Math.min(5, totalPages);
        } else if (currentPage >= totalPages - 2) {
            startPage = Math.max(totalPages - 4, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    aria-current={currentPage === i ? 'page' : undefined}
                    className={`inline-flex items-center border-t-2 ${
                        currentPage === i
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    } px-4 pt-4 text-sm font-medium`}
                >
                    {i}
                </button>
            );
        }

        // Add ellipses and first/last page links if necessary
        if (startPage > 1) {
            pageNumbers.unshift(
                <button
                    key={1}
                    onClick={() => setCurrentPage(1)}
                    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    1
                </button>
            );

            if (startPage > 2) {
                pageNumbers.splice(1, 0, (
                    <span
                        key="start-ellipsis"
                        className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                    >
                        ...
                    </span>
                ));
            }
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push(
                    <span
                        key="end-ellipsis"
                        className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                    >
                        ...
                    </span>
                );
            }

            pageNumbers.push(
                <button
                    key={totalPages}
                    onClick={() => setCurrentPage(totalPages)}
                    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    {totalPages}
                </button>
            );
        }

        return pageNumbers;
    };

    return (
        <div className="p-6 lg:p-8 bg-gray-100 min-h-screen">
            <div className="text-center">
                <h1 className="text-3xl font-bold text-gray-800">Usuarios del sistema</h1>
                <p className="mt-2 text-lg text-gray-600">Lista de usuarios registrados en el sistema.</p>
            </div>

            <div className="mt-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between mb-4">
                    <h2 className="text-lg font-semibold text-gray-900">Usuarios</h2>
                    <button
                        onClick={handleAddUserRedirect}
                        className="mt-4 sm:mt-0 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
                    >
                        Agregar Usuario
                    </button>
                </div>

                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Buscar usuario..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1); // Reset to the first page when searching
                        }}
                        className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>

                {/* Desktop Table View */}
                <div className="hidden lg:block mt-8 overflow-hidden border border-gray-300 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Correo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rol</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentUsers.map((user) => (
                                <tr key={user.user_id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.nombre}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.correo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.perfil}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex space-x-4">
                                        <button
                                            onClick={() => handleDetailRedirect(user.user_id)}
                                            className="text-indigo-600 hover:text-indigo-900"
                                        >
                                            Ver
                                        </button>
                                        <button
                                            onClick={() => handleEditRedirect(user.user_id)}
                                            className="text-indigo-600 hover:text-indigo-900"
                                        >
                                            Editar
                                        </button>
                                        <button
                                            onClick={() => handleDeleteUser(user.user_id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Mobile Card View */}
                <div className="lg:hidden mt-8 grid gap-6">
                    {currentUsers.map((user) => (
                        <div key={user.user_id} className="bg-white border border-gray-300 p-4 rounded-lg shadow-md">
                            <h3 className="text-lg font-semibold text-gray-900">{user.nombre}</h3>
                            <p className="text-sm text-gray-500">{user.correo}</p>
                            <p className="text-sm text-gray-500">{user.perfil}</p>
                            <div className="mt-4 flex space-x-4">
                                <button
                                    onClick={() => handleDetailRedirect(user.user_id)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                >
                                    Ver
                                </button>
                                <button
                                    onClick={() => handleEditRedirect(user.user_id)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                >
                                    Editar
                                </button>
                                <button
                                    onClick={() => handleDeleteUser(user.user_id)}
                                    className="text-red-600 hover:text-red-900"
                                >
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Pagination */}
                <nav className="mt-6 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                    {/* Previous Button */}
                    <div className="-mt-px flex w-0 flex-1">
                        {currentPage > 1 && (
                            <button
                                onClick={() => setCurrentPage(currentPage - 1)}
                                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                <ArrowLongLeftIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400" />
                                Anterior
                            </button>
                        )}
                    </div>

                    {/* Page Numbers */}
                    <div className="hidden md:-mt-px md:flex">
                        {renderPageNumbers()}
                    </div>

                    {/* Next Button */}
                    <div className="-mt-px flex w-0 flex-1 justify-end">
                        {currentPage < totalPages && (
                            <button
                                onClick={() => setCurrentPage(currentPage + 1)}
                                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                Siguiente
                                <ArrowLongRightIcon aria-hidden="true" className="ml-3 h-5 w-5 text-gray-400" />
                            </button>
                        )}
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default ViewUsers;
