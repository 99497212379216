import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUsers from '../../../src/apis/users/useUsers';

const AccommodationVisitants: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<'all' | 'esposa' | 'iglesia'>('all');
  const navigate = useNavigate();
  const { users, loading, error } = useUsers();

  // Filtrar usuarios según los criterios
  const filteredUsers = users.filter(user => {
    const matchesSearch =
      user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.apellidop?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.apellidom?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.iglesia?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.rut.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.pais?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter =
      filter === 'all' ||
      (filter === 'esposa' && user.esposa) ||
      (filter === 'iglesia' && user.iglesia === 'Santo Cristo');

    return matchesSearch && matchesFilter;
  });

  if (loading) {
    return (
      <div className="p-6 bg-white rounded-lg shadow">
        <p className="text-gray-600">Cargando visitantes...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-white rounded-lg shadow">
        <p className="text-red-600">Error al cargar visitantes: {error}</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-semibold mb-4">Visitantes</h2>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar visitante..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      <div className="bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-4">Listado de Visitantes</h3>

        <div className="mb-4 flex space-x-4">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'all' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Todos
          </button>
          <button
            onClick={() => setFilter('esposa')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'esposa' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Con Esposa
          </button>
          <button
            onClick={() => setFilter('iglesia')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'iglesia' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Iglesia
          </button>
        </div>

        {filteredUsers.length === 0 ? (
          <p className="text-gray-500">No se encontraron visitantes.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nombre Completo
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    RUT
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Iglesia
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Con Esposa
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    País
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Acreditado
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredUsers.map((user) => (
                  <tr key={user.user_id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {user.user_id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {`${user.nombre} ${user.apellidop || ''} ${user.apellidom || ''}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.rut}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.iglesia || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.esposa ? 'Sí' : 'No'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.pais || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.acreditado ? 'Sí' : 'No'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => navigate('/Accommodation/manage', { 
                          state: { 
                            visitant: {
                              id: user.user_id.toString(),
                              nombre: user.nombre,
                              apellidop: user.apellidop || '',
                              apellidom: user.apellidom || '',
                              iglesia: user.iglesia || '',
                              asiste_esposa: Boolean(user.esposa),
                              pais: user.pais || '',
                              acreditado: user.acreditado,
                              rut: user.rut,
                              vehiculoPropio: user.vehiculoPropio || false,
                              esposa: user.esposa ? {
                                nombre: user.nombre_esposa || '',
                                apellidop: user.apellidop_esposa || '',
                                apellidom: user.apellidom_esposa || '',
                                rut: user.rut_esposa || ''
                              } : undefined
                            }
                          }
                        })}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                      >
                        Gestionar Hospedaje
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccommodationVisitants;