import React, { useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/GlobalStateContext';
import {
  Bars3Icon,
  ArrowLeftOnRectangleIcon,
  UserIcon,
  QrCodeIcon,
} from '@heroicons/react/24/solid';
import { menusByRole, MenuItem } from '../commons/menu';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const MainLayout: React.FC = () => {
  const { user, logout } = useAppContext();

  const defaultNavigationOptions: MenuItem[] = [
    { name: 'Perfil', href: '/UserProfile', icon: UserIcon },
  ];

  let navigation: MenuItem[] = [];

  const perfil = user?.user.perfil;

  if (typeof perfil === 'string' && Array.isArray(menusByRole[perfil])) {
    navigation = menusByRole[perfil];
  } else {
    navigation = defaultNavigationOptions;
  }

  const location = useLocation();
  const navigate = useNavigate(); // Crear una instancia de useNavigate

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar menu */}
      <div
        className={`${
          isSidebarOpen ? 'block' : 'hidden lg:block'
        } fixed top-0 left-0 h-full w-72 bg-gray-900 z-30`}
      >
        <div className="flex flex-col h-full py-9 px-4 lg:px-6">
          {/* Mobile close button */}
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="absolute top-7 right-4 text-gray-400 lg:hidden"
          >
            <span className="sr-only">Cerrar sidebar</span>
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* User information */}
          <div className="flex items-center gap-4 mt-6 mb-5">
            <Link to="/UserProfile">
              <UserIcon className="h-10 w-10 text-white cursor-pointer" />
            </Link>
            <div className="text-white font-semibold">
              {user?.user.nombre} {user?.user.apellidop} {user?.user.apellidom}
            </div>
          </div>

          {/* Navigation */}
          <nav className="flex-grow">
            <ul className="space-y-2">
              {Array.isArray(navigation) ? (
                navigation.map((item: MenuItem) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      onClick={() => setIsSidebarOpen(false)}
                      className={classNames(
                        location.pathname === item.href
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                        'group flex items-center gap-4 px-4 py-3 rounded-md text-sm font-medium'
                      )}
                    >
                      <item.icon className="h-5 w-5" />
                      {item.name}
                    </Link>
                  </li>
                ))
              ) : (
                <li>
                  <div className="text-red-500 px-4 py-3">
                    Error: Navigation menu is not available.
                  </div>
                </li>
              )}
              
              {/* Logout button */}
              <li>
                <button
                  onClick={() => {
                    setIsSidebarOpen(false);
                    setIsLogoutModalOpen(true);
                  }}
                  className="text-gray-400 hover:bg-gray-800 hover:text-white group flex items-center gap-4 px-4 py-3 rounded-md text-sm font-medium w-full text-left transition-colors duration-200 ease-in-out bg-gray-900 hover:bg-gray-700 active:bg-gray-800 active:text-white"
                >
                  <ArrowLeftOnRectangleIcon className="h-5 w-5" />
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Hamburger button for small screens */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="lg:hidden fixed top-4 left-4 p-2 text-gray-600 z-40"
      >
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        <span className="sr-only">Abrir sidebar</span>
      </button>

      {/* Main Content */}
      <div className="flex-grow bg-gray-100 p-0 lg:pl-72">
        <Outlet />
      </div>

      {/* Logout Confirmation Modal */}
      {isLogoutModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
          {/* Modal content */}
          <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-sm mx-auto text-white">
            <h2 className="text-lg font-semibold mb-4">Cerrar sesión</h2>
            <p className="mb-6">¿Estás seguro de que deseas cerrar sesión?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsLogoutModalOpen(false)}
                className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
              >
                Cancelar
              </button>
              <button
                onClick={() => {
                  setIsLogoutModalOpen(false);
                  logout();
                }}
                className="px-4 py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700"
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainLayout;
