// Import the Product interface from the global context
import { Products } from '../../contexts/GlobalStateContext';

export const getProducts = async (): Promise<Products[]> => {
    try {
        // Make the GET request to the products endpoint
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/productos', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Check if the response was successful
        if (!response.ok) {
            // If the response wasn't ok, throw an error with the status text
            throw new Error(`Error al obtener productos: ${response.statusText}`);
        }

        // Parse the JSON response
        const data = await response.json();

        // Validate that we received an array of products
        if (!Array.isArray(data)) {
            throw new Error('Los datos recibidos no tienen el formato esperado');
        }

        // Map the response data to ensure it matches our Product interface
        const products: Products[] = data.map((item: any) => ({
            id: item.id,
            nombre_producto: item.nombre_producto,
            tipo: item.tipo,
            stock_minimo: Number(item.stock_minimo),
            cantidad: Number(item.cantidad),
            ubicacion: item.ubicacion,
            apto_celiaco: Boolean(item.apto_celiaco),
            apto_hipertenso: Boolean(item.apto_hipertenso),
            apto_diabetico: Boolean(item.apto_diabetico)
        }));

        return products;

    } catch (error) {
        // Log the error for debugging purposes
        console.error('Error al obtener productos:', error);
        
        // Re-throw the error to be handled by the calling component
        throw error;
    }
};

export default getProducts;