interface MealIngredient {
  productoId: number;
  cantidad: number;
}

interface MealData {
  nombre_comida: string;
  fecha: string;
  tipo_comida: string;
  ingredientes: MealIngredient[];
  stock: number;  // Añadimos el campo stock
}

export const createMeal = async (mealData: any): Promise<any> => {
  try {
      const hasInvalidIngredients = mealData.ingredientes.some(
          (ing: { cantidad: number }) => ing.cantidad <= 0
      );
      if (hasInvalidIngredients) {
          throw new Error('No se pueden enviar ingredientes con cantidades inválidas.');
      }

      // Determinamos el stock basado en el tipo de comida
      let stock = 17; // Por defecto para desayuno y cena normal
      if (mealData.tipo_comida === 'Almuerzo') {
          stock = 18; // 17 normales + 1 especial
      }

      const formattedData: MealData = {
          nombre_comida: mealData.nombre_comida,
          fecha: mealData.fecha,
          tipo_comida: mealData.tipo_comida,
          ingredientes: mealData.ingredientes.map((ing: { id_producto: number; cantidad: number }) => ({
              productoId: ing.id_producto,
              cantidad: ing.cantidad
          })),
          stock: stock // Añadimos el stock al payload
      };


      const response = await fetch(
          'https://agy-estudio-biblico-production.up.railway.app/platos',
          {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(formattedData),
          }
      );

      if (!response.ok) {
          const errorData = await response.json().catch(() => null);
          throw new Error(`Error al crear el plato: ${errorData?.message || response.statusText}`);
      }

      return await response.json();
  } catch (error) {
      console.error('Error creating meal:', error);
      throw error;
  }
};