//getmeals.tsx

export interface MealProduct {
    id_producto: number;
    cantidad: number;
    nombre_producto?: string;
  }
  
  export interface Meal {
    id?: number;
    nombre_comida: string;
    fecha: string;
    tipo_comida: string;
    platosProductos: {
      id_producto: number;
      cantidad: number;
      productos: {
        nombre_producto: string;
      };
    }[];
  }
  
  
  export const getMeals = async (): Promise<Meal[]> => {
    try {
      const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/platos', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error al obtener platos: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!Array.isArray(data)) {
        throw new Error('Los datos recibidos no tienen el formato esperado');
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching meals:', error);
      throw error;
    }
  };
