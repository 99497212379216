import React, { useState } from 'react';
import { Accommodation, User as GlobalUser } from '../../contexts/GlobalStateContext';
import { AccommodationInfoCard } from './AccommodationInfoCard';

interface AssignedAccommodationProps {
  accommodation: Accommodation;
  onView: () => void;
  onEdit: () => void;
  onDelete: () => void;
  visitantId: number;
  user: {
    id: string;
    nombre: string;
    apellidop: string;
    apellidom: string;
    iglesia: string;
    asiste_esposa: boolean;
    pais: string;
    acreditado: boolean;
    rut: string;
    vehiculoPropio: boolean;
    email?: string;
    telefono?: string;
    user_id?: number;
    correo?: string;
    esposa?: {
      nombre: string;
      apellidop: string;
      apellidom: string;
      rut: string;
      pais?: string;
    };
  };
}

const AssignedAccommodation: React.FC<AssignedAccommodationProps> = ({
  accommodation,
  onView,
  onEdit,
  onDelete,
  visitantId,
  user
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (window.confirm('¿Está seguro de que desea eliminar esta asignación de alojamiento?')) {
      setIsDeleting(true);
      try {
        await onDelete();
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <>
      <div className="mt-4 pt-4 border-t flex justify-between items-center">
        <div className="bg-gray-100 p-3 rounded-md flex-grow flex justify-between items-center mr-4">
          <p className="text-sm font-medium text-gray-900">
            Alojamiento asignado: {accommodation.nombre} - {accommodation.direccion}
          </p>
          <div className="flex gap-4">
            <button 
              onClick={() => setShowDetails(true)}
              className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
            >
              Ver detalles
            </button>
            <button 
              onClick={onEdit}
              className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
            >
              Editar
            </button>
            <button 
              onClick={handleDelete}
              disabled={isDeleting}
              className="text-red-600 hover:text-red-900 text-sm font-medium disabled:opacity-50"
            >
              {isDeleting ? 'Eliminando...' : 'Eliminar'}
            </button>
          </div>
        </div>
        <button 
          className="px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded hover:bg-indigo-700 whitespace-nowrap"
          onClick={onEdit}
        >
          Cambiar asignación
        </button>
      </div>

      {showDetails && (
        <AccommodationInfoCard
          accommodation={accommodation}
          onClose={() => setShowDetails(false)}
          onAssign={onEdit}
          visitantId={visitantId}
          readOnly
          reservas={[]}
          habitaciones={accommodation.habitaciones || []}
        />
      )}
    </>
  );
};

export default AssignedAccommodation;