import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import CSVUploader from './CSVUploader';  // Cambiar a import default
import { AccommodationList } from './AccommodationList';

const ManageAccommodations: React.FC = () => {
  const [showUploader, setShowUploader] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Gestión de Hospedajes</h2>
        <div className="space-x-4">
          <button 
            onClick={() => setShowUploader(true)}
            className="border border-indigo-600 text-indigo-600 px-4 py-2 rounded-md flex items-center"
          >
            <Upload className="w-4 h-4 mr-2" />
            Cargar CSV
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}

      {showUploader && (
        <CSVUploader
          onClose={() => setShowUploader(false)}
          onError={(msg: string) => setError(msg)}
        />
      )}

      <AccommodationList />
    </div>
  );
};

export default ManageAccommodations;