//CustodySections.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustodySpace, useAppContext } from '../../contexts/GlobalStateContext';
import { createCustody } from '../../apis/custody/createCustody';
import { deleteCustody } from '../../apis/custody/useCustody';

const CustodySections: React.FC = () => {
  const { custodySpaces, addCustodySpace, removeCustodySpace, getCustodySpaces } = useAppContext();
  const navigate = useNavigate();

  const [newSpace, setNewSpace] = useState<Omit<CustodySpace, 'id'>>({
    ubicacion: '',
    capacidad: 'Maleta',
    ocupado: false,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<'all' | 'occupied' | 'available'>('all');
  const [showModal, setShowModal] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<number | null>(null);

  useEffect(() => {
    const loadSpaces = async () => {
      try {
        const spaces = await getCustodySpaces();
        if (Array.isArray(spaces)) {
          console.log('Spaces loaded:', spaces);
        }
      } catch (error) {
        console.error('Error loading spaces:', error);
      }
    };
    
    loadSpaces();
  }, []); // Se ejecutará solo al montar el componente

  // Segundo useEffect para logging detallado (el que ya tenías)
  useEffect(() => {
    console.log('Detailed custody spaces:', custodySpaces.map(space => ({
      ...space,
      hasUser: !!space.user,
      userDetails: space.user ? {
        id: space.user.user_id,
        nombre: space.user.nombre,
        rut: space.user.rut
      } : null
    })));
  }, [custodySpaces]);


  // Filter spaces based on search term and filter selection
  const filteredSpaces = custodySpaces.filter((space) => {
    const matchesSearch =
      space.ubicacion.toLowerCase().includes(searchTerm.toLowerCase()) ||
      space.capacidad.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter =
      filter === 'all' ||
      (filter === 'occupied' && space.ocupado) ||
      (filter === 'available' && !space.ocupado);

    return matchesSearch && matchesFilter;
  });

  // Update handleAddSpace to use the createCustody API
  const handleAddSpace = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log('Submitting new space:', newSpace); // Log data being submitted

      // Call the createCustody API to add the new space to the database
      const createdSpace = await createCustody(newSpace);
      console.log('Created space response:', createdSpace); // Log the created space

      // Update local context state to include the newly created space
      addCustodySpace(createdSpace);

      // Reset the form fields after successful addition
      setNewSpace({ ubicacion: '', capacidad: 'Maleta', ocupado: false });
    } catch (error) {
      console.error('Failed to add custody space:', error);
    }
  };

  const confirmDelete = (id: number) => {
    setSelectedSpace(id);
    setShowModal(true);
  };
  
  const handleDeleteSpace = async () => {
    if (selectedSpace !== null) {
      try {
        await deleteCustody(selectedSpace);
        removeCustodySpace(selectedSpace);
        setShowModal(false);
        setSelectedSpace(null);
      } catch (error) {
        console.error('Failed to delete custody space:', error);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedSpace(null);
  };
  
    // Helper function to render user information consistently
    const renderUserInfo = (space: CustodySpace) => {
      if (!space.ocupado) return null;
  
      const formatUserInfo = () => {
          if (space.user) {
              return {
                  fullName: `${space.user.nombre} ${space.user.apellidop} ${space.user.apellidom}`.trim(),
                  rut: space.user.rut
              };
          }
          return null;
      };
  
      const userInfo = formatUserInfo();
  
      return (
          <div className="mt-2 p-2 bg-gray-50 rounded-md">
              <h4 className="text-sm font-medium text-gray-700">Asignado a:</h4>
              <p className="text-sm text-gray-600">
                  {userInfo 
                      ? userInfo.fullName
                      : space.user?.user_id || space.usuarioId 
                          ? `Usuario #${space.user?.user_id || space.usuarioId}`
                          : 'Usuario no identificado'}
              </p>
              {userInfo?.rut && (
                  <p className="text-sm text-gray-600">
                      <span className="text-gray-500">RUT:</span> {userInfo.rut}
                  </p>
              )}
          </div>
      );
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-semibold mb-4">Secciones de Custodia</h2>

      {/* Buscador */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar espacio..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      {/* Formulario para agregar espacios de custodia */}
      <form
        onSubmit={handleAddSpace}
        className="bg-white p-4 rounded shadow mb-6"
      >
        <h3 className="text-lg font-semibold mb-4">
          Agregar Espacio de Custodia
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Ubicación
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={newSpace.ubicacion}
              onChange={(e) =>
                setNewSpace({ ...newSpace, ubicacion: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label
              htmlFor="capacity"
              className="block text-sm font-medium text-gray-700"
            >
              Capacidad
            </label>
            <select
              id="capacity"
              name="capacity"
              value={newSpace.capacidad}
              onChange={(e) =>
                setNewSpace({
                  ...newSpace,
                  capacidad: e.target.value as 'Maleta' | 'Bolso de mano',
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="Maleta">Maleta</option>
              <option value="Bolso de mano">Bolso de mano</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
        >
          Agregar Espacio
        </button>
      </form>

      {/* Lista de espacios de custodia */}
      <div className="bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-4">Espacios de custodia</h3>

        {/* Botones para filtrar espacios */}
        <div className="mb-4 flex space-x-4">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'all' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Todos
          </button>
          <button
            onClick={() => setFilter('occupied')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'occupied' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Ocupados
          </button>
          <button
            onClick={() => setFilter('available')}
            className={`px-4 py-2 rounded-md text-sm font-semibold ${
              filter === 'available' ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-indigo-500`}
          >
            Disponibles
          </button>
        </div>
        {filteredSpaces.length === 0 ? (
          <p className="text-gray-500">No se encontraron espacios de custodia.</p>
        ) : (
          <>
            {/* Mobile View */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:hidden">
              {filteredSpaces.map((space) => {
                console.log('Space data before mobile render:', space);
                return (
                  <div key={space.id} className="bg-white border border-gray-300 p-4 rounded-lg shadow-md">
                    <h3 className="text-lg font-semibold text-gray-900">ID: {space.id}</h3>
                    <p className="text-sm text-gray-500">Ubicación: {space.ubicacion}</p>
                    <p className="text-sm text-gray-500">Capacidad: {space.capacidad}</p>
                    <p className="text-sm text-gray-500">Ocupado: {space.ocupado ? 'Sí' : 'No'}</p>
                    {space.ocupado && (
                      <div className="mt-2 p-2 bg-gray-50 rounded-md">
                        <h4 className="text-sm font-medium text-gray-700">Asignado a:</h4>
                        <p className="font-medium">
                          {space.user 
                            ? `${space.user.nombre} ${space.user.apellidop} ${space.user.apellidom}`
                            : 'Usuario no identificado'}
                        </p>
                        {space.user?.rut && (
                          <p className="text-xs text-gray-400 mt-1">
                            RUT: {space.user.rut}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="flex space-x-4 mt-4">
                      {space.ocupado ? (
                        <button
                          className="text-lime-600 hover:text-lime-900"
                          onClick={() => {
                            navigate('/Custody/deliver_luggage', { state: { type: 'EntregarEspacio', spaceId: space.id } });
                          }}
                        >
                          Entregar
                        </button>
                      ) : (
                        <>
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => {
                              navigate('/Custody/receive_luggage', { state: { type: 'AsignarEspacio', spaceId: space.id } });
                            }}
                          >
                            Asignar
                          </button>
                          <button
                            onClick={() => confirmDelete(space.id)}
                            className="text-red-600 hover:text-red-800"
                          >
                            Eliminar
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Desktop View - Table Layout */}
            <div className="hidden lg:block">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ID Espacio
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ubicación
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Capacidad
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Usuario Asignado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredSpaces.map((space) => {
                    console.log('Space data before desktop render:', space);
                    return (
                      <tr key={space.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {space.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {space.ubicacion}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {space.capacidad}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {space.ocupado ? 'Ocupado' : 'Disponible'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {space.ocupado ? (
                            <div>
                              <p className="font-medium">
                                {space.user 
                                  ? `${space.user.nombre} ${space.user.apellidop} ${space.user.apellidom}`
                                  : 'Usuario no identificado'}
                              </p>
                              {space.user?.rut && (
                                <p className="text-xs text-gray-400 mt-1">
                                  RUT: {space.user.rut}
                                </p>
                              )}
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          {space.ocupado ? (
                            <button
                              className="text-lime-600 hover:text-lime-900"
                              onClick={() => navigate('/Custody/deliver_luggage', { 
                                state: { type: 'EntregarEspacio', spaceId: space.id } 
                              })}
                            >
                              Entregar
                            </button>
                          ) : (
                            <>
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mr-4"
                                onClick={() => navigate('/Custody/receive_luggage', { 
                                  state: { type: 'AsignarEspacio', spaceId: space.id } 
                                })}
                              >
                                Asignar
                              </button>
                              <button
                                onClick={() => confirmDelete(space.id)}
                                className="text-red-600 hover:text-red-800"
                              >
                                Eliminar
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-20 z-40"></div>
          <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-[90%] md:max-w-sm mx-auto text-white">
            <h3 className="text-lg font-semibold mb-4 text-center break-words whitespace-normal">
              ¿Estás seguro de que deseas eliminar este espacio?
            </h3>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
                onClick={closeModal}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700"
                onClick={handleDeleteSpace}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustodySections;