import React, { useState, useEffect } from 'react';
import { getTodaysMeals, deliverMeal, MealDelivery } from '../../apis/inventory/deliverMealsService';
import { useAppContext } from '../../contexts/GlobalStateContext';

const DELIVERY_COUNT_KEY = 'mealDeliveryCount';

const DeliverMeals: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedMeal, setSelectedMeal] = useState<MealDelivery | null>(null);
    const [meals, setMeals] = useState<MealDelivery[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [deliveryCount, setDeliveryCount] = useState<Record<number, number>>({});
    const { refreshProducts } = useAppContext();

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        const storedData = localStorage.getItem(DELIVERY_COUNT_KEY);
        
        if (storedData) {
            const { date, counts } = JSON.parse(storedData);
            if (date === today) {
                setDeliveryCount(counts);
            } else {
                localStorage.removeItem(DELIVERY_COUNT_KEY);
            }
        }
    }, []);

    useEffect(() => {
        if (Object.keys(deliveryCount).length > 0) {
            const today = new Date().toISOString().split('T')[0];
            localStorage.setItem(DELIVERY_COUNT_KEY, JSON.stringify({
                date: today,
                counts: deliveryCount
            }));
        }
    }, [deliveryCount]);

    useEffect(() => {
        loadTodaysMeals();
    }, []);

    const loadTodaysMeals = async () => {
        try {
            setLoading(true);
            const todaysMeals = await getTodaysMeals();
            setMeals(todaysMeals);
            
            const storedData = localStorage.getItem(DELIVERY_COUNT_KEY);
            const existingCounts = storedData ? JSON.parse(storedData).counts : {};
            
            const initialCounts: Record<number, number> = { ...existingCounts };
            todaysMeals.forEach((meal) => {
                if (!(meal.id in initialCounts)) {
                    initialCounts[meal.id] = 0;
                }
            });
            
            setDeliveryCount(initialCounts);
        } catch (err) {
            setError('Error al cargar los platos del día');
            console.error('Error loading meals:', err);
        } finally {
            setLoading(false);
        }
    };

    const getRemainingTables = (meal: MealDelivery) => {
        const delivered = deliveryCount[meal.id] || 0;
        const totalTables = meal.menu_type === 'especial' ? 1 : 17;
        return totalTables - delivered;
    };

    const getButtonColor = (mealType: string, menuType: 'normal' | 'especial') => {
        // Colores distintivos para cada tipo de comida y menú
        if (menuType === 'normal') {
            switch (mealType) {
                case 'Desayuno':
                    return 'bg-blue-500 hover:bg-blue-600';
                case 'Almuerzo':
                    return 'bg-green-500 hover:bg-green-600';
                case 'Cena':
                    return 'bg-purple-500 hover:bg-purple-600';
                default:
                    return 'bg-blue-500 hover:bg-blue-600';
            }
        } else {
            // Colores para menú especial
            switch (mealType) {
                case 'Almuerzo':
                    return 'bg-orange-500 hover:bg-orange-600';
                case 'Cena':
                    return 'bg-pink-500 hover:bg-pink-600';
                default:
                    return 'bg-orange-500 hover:bg-orange-600';
            }
        }
    };

    const renderTableCount = (meal: MealDelivery) => {
        const remaining = getRemainingTables(meal);
        let bgColor = 'bg-gray-200';
        if (remaining === 0) bgColor = 'bg-red-200 text-red-800';
        else if (remaining <= 5) bgColor = 'bg-yellow-200 text-yellow-800';

        return (
            <span className={`ml-2 px-2 py-1 text-xs font-semibold rounded-full ${bgColor}`}>
                {remaining} {remaining === 1 ? 'mesa' : 'mesas'}
            </span>
        );
    };

    const handleOpenModal = (meal: MealDelivery) => {
        const remaining = getRemainingTables(meal);
        if (remaining === 0) {
            setError('No quedan mesas por servir para este servicio.');
            return;
        }
        setSelectedMeal(meal);
        setShowModal(true);
    };

    const handleConfirmDelivery = async () => {
        if (!selectedMeal) return;
    
        try {
            const remaining = getRemainingTables(selectedMeal);
            console.log('Remaining tables before delivery:', remaining); // Debug
            
            if (remaining <= 0) {
                setError('No quedan mesas por servir para este servicio.');
                return;
            }
    
            await deliverMeal(selectedMeal.id);
            
            setDeliveryCount(prev => {
                const currentCount = prev[selectedMeal.id] || 0;
                console.log('Current count for meal:', currentCount); // Debug
                
                const newCounts: Record<number, number> = {
                    ...prev,
                    [selectedMeal.id]: currentCount + 1
                };
    
                // Guardar en localStorage
                const today = new Date().toISOString().split('T')[0];
                localStorage.setItem(DELIVERY_COUNT_KEY, JSON.stringify({
                    date: today,
                    counts: newCounts
                }));
    
                return newCounts;
            });
    
            await refreshProducts();
            setShowModal(false);
            setSelectedMeal(null);
            setError(null);
        } catch (err) {
            const error = err as Error;
            setError(error.message || 'Error al registrar la entrega del plato');
            console.error('Error confirming delivery:', error);
        }
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h2 className="text-2xl font-semibold mb-6">Entrega de Menús</h2>

            {error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-red-700">{error}</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Menú Normal */}
            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Menú Normal</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {meals
                        .filter(meal => meal.menu_type === 'normal')
                        .map(meal => (
                            <div key={meal.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
                                <div className="p-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900">
                                                {meal.nombre_comida}
                                            </h3>
                                            <p className="text-sm text-gray-500">{meal.tipo_comida}</p>
                                        </div>
                                        {renderTableCount(meal)}
                                    </div>
                                    <div className="space-y-2 mb-4">
                                        {meal.platosProductos?.map((prod, idx) => (
                                            <p key={idx} className="text-sm text-gray-600">
                                                • {prod.productos.nombre_producto}: {prod.cantidad} unidades
                                            </p>
                                        ))}
                                    </div>
                                    <button
                                        onClick={() => handleOpenModal(meal)}
                                        disabled={getRemainingTables(meal) === 0}
                                        className={`w-full px-4 py-2 font-bold rounded-md transition-colors
                                            ${getRemainingTables(meal) === 0 
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                                                : getButtonColor(meal.tipo_comida, 'normal') + ' text-white'}`}
                                    >
                                        Entregar {meal.tipo_comida}
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            {/* Menú Especial */}
            <div>
                <h3 className="text-xl font-semibold mb-4">Menú Especial</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {meals
                        .filter(meal => meal.menu_type === 'especial')
                        .map(meal => (
                            <div key={meal.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
                                <div className="p-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900">
                                                {meal.nombre_comida}
                                            </h3>
                                            <p className="text-sm text-gray-500">{meal.tipo_comida}</p>
                                        </div>
                                        {renderTableCount(meal)}
                                    </div>
                                    <div className="space-y-2 mb-4">
                                        {meal.platosProductos?.map((prod, idx) => (
                                            <p key={idx} className="text-sm text-gray-600">
                                                • {prod.productos.nombre_producto}: {prod.cantidad} unidades
                                            </p>
                                        ))}
                                    </div>
                                    <button
                                        onClick={() => handleOpenModal(meal)}
                                        disabled={getRemainingTables(meal) === 0}
                                        className={`w-full px-4 py-2 font-bold rounded-md transition-colors
                                            ${getRemainingTables(meal) === 0 
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                                                : getButtonColor(meal.tipo_comida, 'especial') + ' text-white'}`}
                                    >
                                        Entregar {meal.tipo_comida}
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            {/* Modal de Confirmación */}
            {showModal && selectedMeal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">
                                Confirmar entrega de {selectedMeal.nombre_comida}
                            </h3>
                            <div className="bg-gray-50 p-4 rounded-md mb-4">
                                <p className="text-sm text-gray-700 mb-2">
                                    Se entregará una mesa:
                                </p>
                                <ul className="list-disc pl-5 space-y-1">
                                    {selectedMeal.platosProductos?.map((prod, idx) => (
                                        <li key={idx} className="text-sm text-gray-600">
                                            {prod.productos.nombre_producto}: {prod.cantidad} unidades
                                        </li>
                                    ))}
                                </ul>
                                <p className="mt-3 text-sm text-gray-700">
                                    Quedarán{' '}
                                    <span className="font-medium">
                                        {getRemainingTables(selectedMeal) - 1}
                                    </span>{' '}
                                    mesas por entregar para este servicio.
                                </p>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleConfirmDelivery}
                                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                                >
                                    Confirmar Entrega
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeliverMeals;