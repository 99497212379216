import { useState } from 'react';
import { useAppContext } from '../../contexts/GlobalStateContext';

interface UpdateProductQuantityParams {
    productId: number;
    newQuantity: number;
    }

    export const useProducts = () => {
    const { refreshProducts } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const updateProductQuantity = async ({ productId, newQuantity }: UpdateProductQuantityParams) => {
        setIsLoading(true);
        setError(null);
        try {
        const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/productos/${productId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ cantidad: newQuantity })
        });

        if (!response.ok) throw new Error('Error al actualizar la cantidad del producto');
        await refreshProducts();
        return true;
        } catch (err) {
        setError(err instanceof Error ? err.message : 'Error al actualizar el producto');
        console.error('Error updating product quantity:', err);
        return false;
        } finally {
        setIsLoading(false);
        }
    };

    const deleteProduct = async (productId: number) => {
        setIsLoading(true);
        setError(null);
        try {
        const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/productos/${productId}`, {
            method: 'DELETE'
        });

        if (!response.ok) throw new Error('Error al eliminar el producto');
        await refreshProducts();
        return true;
        } catch (err) {
        setError(err instanceof Error ? err.message : 'Error al eliminar el producto');
        console.error('Error deleting product:', err);
        return false;
        } finally {
        setIsLoading(false);
        }
    };

    return {
        updateProductQuantity,
        deleteProduct,
        isLoading,
        error
    };
};